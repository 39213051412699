const availableExtras: { code: string; name: string }[] = [
  { code: "has_air_conditioner", name: "Aire acondicionado" },
  { code: "has_elevator", name: "Ascensor" },
  { code: "has_chimney", name: "Chimenea" },
  { code: "is_furnished", name: "Amueblado" },
  { code: "is_furnished_kitchen", name: "Cocina amueblada" },
  { code: "home_appliances", name: "Electrodomésticos" },
  { code: "reinforced_door", name: "Puerta reforzada" },
  { code: "has_garden", name: "Jardín" },
  { code: "has_terrace", name: "Terraza" },
  { code: "has_parking", name: "Parking" },
  { code: "has_pool", name: "Piscina" },
];

export default availableExtras;
