import Plausible from "plausible-tracker";

const plausible = Plausible({
  trackLocalhost: false,
  apiHost: "https://analytics.serviestate.com",
  domain: "crm.serviestate.com",
});

plausible.enableAutoPageviews();

export default plausible;
