import {
  InputAdornment,
  IconButton,
  styled,
  SxProps,
  Theme,
  TextField,
} from "@mui/material";
import React, { forwardRef, useRef } from "react";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import { debounce } from "@mui/material";
import { useCombinedRefs } from "../../hooks/use-combined-refs";

export type InputSearchProps = {
  id: string;
  label: string;
  iconPosition?: "start" | "end";
  disabled?: boolean;
  readOnly?: boolean;
  value?: string;
  size?: "medium" | "small";
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  onMouseDown?: React.MouseEventHandler<HTMLInputElement>;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
};

export const InputSearch = forwardRef<HTMLInputElement, InputSearchProps>(
  (
    {
      id,
      value,
      fullWidth,
      disabled,
      label,
      iconPosition,
      size,
      sx,
      readOnly,
      onChange,
      onClick,
      onMouseDown,
    },
    ref
  ) => {
    iconPosition = iconPosition ?? "end";
    const inputRef = useRef<HTMLInputElement>(null);
    const combinedRef = useCombinedRefs(ref, inputRef);

    const adornment = (
      <InputAdornment position={iconPosition}>
        <IconButton aria-label="search" edge={iconPosition}>
          <SearchIcon />
        </IconButton>
      </InputAdornment>
    );

    return (
      <CustomSearcher
        variant="outlined"
        size={size ?? "small"}
        id={id}
        fullWidth={fullWidth}
        sx={{ ...sx }}
        label={label}
        defaultValue={value}
        disabled={disabled}
        onClick={onClick}
        onMouseDown={onMouseDown}
        inputRef={combinedRef}
        InputProps={{
          readOnly,
          startAdornment: iconPosition === "start" && adornment,
          endAdornment: iconPosition === "end" && adornment,
        }}
        onChange={onChange ? debounce(onChange, 500) : undefined}
      />
    );
  }
);

const CustomSearcher = styled(TextField)(({ theme }) => ({
  "& .MuiFormLabel-root": {
    fontWeight: theme.typography.fontWeightBold,
  },
  "& .MuiInputBase-root": {
    borderRadius: "40px",
    backgroundColor: "#F9F9FB",
    borderColor: "#EFEFEF",
  },
}));
