import { FC, Suspense } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuthProvider } from "../../providers/auth.provider";
import { useUserProvider } from "../../providers/user.provider";
import { PageLoading } from "../organisms/page-loading";
import { Layout } from "../templates/_layout";
import { useAgencyProvider } from "../../providers/agency.provider";
import { useSubscriptionProvider } from "../../providers/subscription.provider";

export const ProtectedRoute: FC<{ hiddenLayout?: boolean }> = ({
  hiddenLayout = false,
}) => {
  const [{ isAuthenticated }] = useAuthProvider();
  const { user } = useUserProvider();
  const { agency } = useAgencyProvider();
  const { subscription } = useSubscriptionProvider();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (!user) {
    return <PageLoading />;
  }

  if (
    user.agencies.length === 0 &&
    location.pathname !== "/onboarding/welcome-agent"
  ) {
    return <Navigate to="/onboarding/welcome-agent" replace />;
  }

  if (!agency) {
    return <PageLoading />;
  }

  if (
    agency.flags.onboarding !== true &&
    location.pathname !== "/onboarding/welcome"
  ) {
    return <Navigate to="/onboarding/welcome" replace />;
  }

  if (!subscription) {
    return <PageLoading />;
  }

  if (
    subscription.state === "unavailable" &&
    location.pathname !== "/onboarding/renovate-subscription"
  ) {
    return <Navigate to="/onboarding/renovate-subscription" replace />;
  }

  if (hiddenLayout) {
    return (
      <Suspense fallback={<PageLoading />}>
        <Outlet />
      </Suspense>
    );
  }

  return (
    <Layout>
      <Suspense fallback={<PageLoading />}>
        <Outlet />
      </Suspense>
    </Layout>
  );
};
