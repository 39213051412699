import {
  Alert,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  LinearProgress,
  Typography,
  styled,
} from "@mui/material";
import { FC, useCallback, useEffect, useMemo } from "react";
import { customScrollbar } from "../../ServiestateTheme";
import { useNotificationsProvider } from "../../providers/notifications.provider";
import { UserNotification } from "@serviestate/shared";
import { useAPI } from "../../hooks/use-api";
import { useDialogController, useFetchController } from "@serviestate/react-ui";
import { UserAvatar } from "../atoms/user-avatar";
import dayjs from "dayjs";
import { LuFileSignature } from "react-icons/lu";
import { SignatureInfoDialog } from "./dialogs/signature-info-dialog";
import { FiUserPlus } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import { HEADER_HEIGHT } from "../molecules/header";
import { IoCloseOutline } from "react-icons/io5";

export type SidebarNotificationsProps = {
  open: boolean;
  onClose: () => void;
};

export const SidebarNotifications: FC<SidebarNotificationsProps> = ({
  open,
  onClose,
}) => {
  const { notifications, setAllAsRead } = useNotificationsProvider();

  const newNotifications = notifications.filter((x) => !!!x.read_at);
  const oldNotifications = notifications.filter((x) => !!x.read_at);
  const location = useLocation();

  useEffect(() => {
    onClose();
  }, [location, onClose]);

  useEffect(() => {
    if (open) {
      setAllAsRead();
    }
  });

  return (
    <RootDrawer anchor="right" open={open} onClose={onClose}>
      <Header>
        <Typography variant="h6" flex={1}>
          Notificaciones{" "}
          {newNotifications.length > 0 && `(${newNotifications.length})`}
        </Typography>
        <IconButton onClick={onClose}>
          <IoCloseOutline />
        </IconButton>
      </Header>
      <Box
        padding={2}
        sx={{ display: "flex", flexDirection: "column", gap: 1 }}
      >
        {notifications.length === 0 && <Alert>No tienes notificaciones</Alert>}
        {newNotifications.length > 0 && (
          <Typography fontWeight="bold">Nuevas</Typography>
        )}
        {newNotifications.map((x) => {
          const NotificationComponent = NotificationTypes[x.type_id];
          return (
            <Box key={x.id}>
              {NotificationComponent ? (
                <NotificationComponent notification={x} onClose={onClose} />
              ) : (
                <Box>Notificación {x.type_id}</Box>
              )}
              <Divider sx={{ my: 1 }} />
            </Box>
          );
        })}
        {oldNotifications.length > 0 && (
          <Typography fontWeight="bold">Anteriores</Typography>
        )}
        {oldNotifications.map((x) => {
          const NotificationComponent = NotificationTypes[x.type_id];
          return (
            <Box key={x.id}>
              {NotificationComponent ? (
                <NotificationComponent notification={x} onClose={onClose} />
              ) : (
                <Box>Notificación {x.type_id}</Box>
              )}
              <Divider sx={{ my: 1 }} />
            </Box>
          );
        })}
      </Box>
    </RootDrawer>
  );
};

const NewIncommingMessageNotification: FC<NotificationComponentProps> = ({
  notification,
  onClose,
}) => {
  const api = useAPI();
  const fetchMessage = useCallback(
    () => api.messages.findOne(notification.metadata.message_id as string),
    [api, notification]
  );

  const { data, loading } = useFetchController(fetchMessage, {
    autoload: true,
  });

  const previewBody = useMemo(() => {
    if (!data) return "";
    if (data.body_type === "text")
      return data.body.length > 0
        ? data.body.substring(0, 100) + "..."
        : data.body;
    if (data.body_type === "html") {
      const p = document.createElement("p");
      p.innerHTML = data.body;
      const text = p.textContent?.trim();
      if (!text) return "";
      return text.length > 0 ? text.substring(0, 100) + "..." : text;
    }
    return "";
  }, [data]);

  return (
    <Box>
      {loading && <LinearProgress />}
      {data && (
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Box>
            <UserAvatar username={data.contact.display_name} />
          </Box>
          <Box>
            <Typography
              textOverflow="ellipsis"
              fontWeight="bold"
              fontStyle="italic"
            >
              Mensaje de {data.contact.display_name}
            </Typography>
            <Typography variant="caption">
              {dayjs(data.created_at).fromNow()}
            </Typography>
            <Typography>{previewBody}</Typography>
            <Button
              size="small"
              href={`/messages/${data.contact_id}`}
              onClick={onClose}
            >
              Ver mensaje completo
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const PollAnswerReceivedNotification: FC<NotificationComponentProps> = ({
  notification,
  onClose,
}) => {
  return (
    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
      <Box>
        <UserAvatar username={notification.metadata.contact_name as string} />
      </Box>
      <Box>
        <Typography
          textOverflow="ellipsis"
          fontWeight="bold"
          fontStyle="italic"
        >
          {notification.metadata.contact_name as string} ha respondido a la
          encuesta {notification.metadata.poll_title as string}
        </Typography>
        <Typography variant="caption">
          {dayjs(notification.created_at).fromNow()}
        </Typography>
        <Button
          size="small"
          href={`/polls/${notification.metadata.poll_id as string}`}
          onClick={onClose}
        >
          Ver encuesta
        </Button>
      </Box>
    </Box>
  );
};

const SignatureCompletedNotification: FC<NotificationComponentProps> = ({
  notification,
}) => {
  const api = useAPI();
  const fetchSignature = useCallback(
    () => api.signatures.detail(notification.metadata.signature_id as string),
    [api, notification]
  );

  const {
    visible: showSignatureDialog,
    open: handleOpenSignatureDialog,
    close: handleCloseSignatureDialog,
  } = useDialogController();

  const { data, loading } = useFetchController(fetchSignature, {
    autoload: true,
  });

  return (
    <Box>
      {loading && <LinearProgress />}
      {data && (
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Box>
            <LuFileSignature size={35} color="#306f9a" />
          </Box>
          <Box>
            <Typography
              textOverflow="ellipsis"
              fontWeight="bold"
              fontStyle="italic"
            >
              Se ha completado la firma del documento
            </Typography>
            <Typography variant="caption">
              {dayjs(data.completed_at).fromNow()}
            </Typography>
            <Button size="small" onClick={handleOpenSignatureDialog}>
              Ver documento y firma
            </Button>
          </Box>
          <SignatureInfoDialog
            open={showSignatureDialog}
            onClose={handleCloseSignatureDialog}
            signatureId={data.id}
          />
        </Box>
      )}
    </Box>
  );
};

const NewAcquisitionProperties: FC<NotificationComponentProps> = ({
  notification,
  onClose,
}) => {
  return (
    <Box>
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Box>
          <FiUserPlus size={35} color="#306f9a" />
        </Box>
        <Box>
          <Typography
            textOverflow="ellipsis"
            fontWeight="bold"
            fontStyle="italic"
          >
            {(notification.metadata.external_properties as any).length} Nuevos
            inmuebles en {(notification.metadata.zone as any).name}
          </Typography>
          <Typography variant="caption">
            {dayjs(notification.created_at).fromNow()}
          </Typography>
          <Button size="small" href={`/acquisition/news`} onClick={onClose}>
            Explorar leads
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const NotificationTypes: Record<string, FC<NotificationComponentProps>> = {
  "new-incoming-message": NewIncommingMessageNotification,
  "signature-completed": SignatureCompletedNotification,
  "new-acquisition-properties": NewAcquisitionProperties,
  "poll-answer-received": PollAnswerReceivedNotification,
};

export type NotificationComponentProps = {
  notification: UserNotification;
  onClose: () => void;
};

const RootDrawer = styled(Drawer)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 100,
  "& .MuiDrawer-paper": {
    width: "100%",
    boxShadow: theme.shadows[5],
    ...customScrollbar,
    [theme.breakpoints.up("sm")]: {
      width: "calc(100% - 23rem)",
      maxWidth: "23rem",
    },
  },
}));

const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  gap: theme.spacing(1.5),
  borderBottom: `1px solid ${theme.palette.divider}`,
  height: HEADER_HEIGHT,
  textAlign: "left",
}));
