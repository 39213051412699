import { Avatar, Badge, styled } from "@mui/material";
import { FC } from "react";
import { getContrastColor, getPastelColor } from "../../utils";
import { UserStatus } from "@serviestate/shared";

export type UserAvatarProps = {
  username: string;
  avatar?: string | null;
  className?: string;
  size?: number;
  status?: UserStatus | null;
};

export const UserAvatar: FC<UserAvatarProps> = ({
  username,
  avatar,
  className,
  size,
  status,
}) => {
  size = size ?? 40;
  const fontSize = size / 25;
  const backgroundColor = getPastelColor(username);
  const textColor = getContrastColor(backgroundColor);

  const avatarComponent = avatar ? (
    <Avatar
      className={className}
      sx={{ width: size, height: size }}
      alt={`Foto de perfil de ${username}`}
      src={avatar}
    />
  ) : (
    <Avatar
      sx={{
        width: size,
        height: size,
        fontSize: fontSize + "em",
        backgroundColor: backgroundColor,
        color: textColor,
      }}
      className={className}
    >
      {username[0]?.toLocaleUpperCase()}
    </Avatar>
  );

  if (status) {
    return (
      <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        variant="dot"
        sensor_color={status.color_icon}
      >
        {avatarComponent}
      </StyledBadge>
    );
  } else {
    return avatarComponent;
  }
};

const StyledBadge = styled(Badge)<{ sensor_color: string }>(
  ({ theme, sensor_color }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: sensor_color,
      color: sensor_color,
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  })
);
