import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  styled,
} from "@mui/material";
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDialogController } from "@serviestate/react-ui";
import { useAuthProvider } from "../../providers/auth.provider";
import { customScrollbar } from "../../ServiestateTheme";
import { HEADER_HEIGHT, Header } from "../molecules/header";
import { SidebarMenu, SIDEBAR_WIDTH } from "../molecules/sidebar-menu";
import { SidebarNotifications } from "../organisms/sidebar-notifications";
import { RootLayoutProvider } from "../../providers/root-layout.provider";
import { useSubscriptionProvider } from "../../providers/subscription.provider";
import { useActivePage } from "../../hooks/use-active-page";
import { InitialSteps } from "../organisms/initial-steps";
import { useLocation } from "react-router-dom";
import { Serena } from "../organisms/serena.chat";

export type LayoutProps = PropsWithChildren<{}>;

export const Layout: FC<LayoutProps> = ({ children }) => {
  const rootLayoutElement = useRef<HTMLDivElement>(null);
  const {
    visible: menuVisible,
    toggle: toggleMenu,
    close: closeMenu,
  } = useDialogController();

  const {
    visible: notificationsVisible,
    toggle: toggleNotifications,
    close: closeNotifications,
  } = useDialogController();

  const [{ isAuthenticated }] = useAuthProvider();

  const location = useLocation();

  useEffect(() => {
    closeMenu();
  }, [location, closeMenu]);

  return isAuthenticated ? (
    <RootLayoutProvider element={rootLayoutElement}>
      <Box
        minHeight="100vh"
        sx={{ display: "flex", flexDirection: "column", position: "relative" }}
      >
        <Header
          isMenuExpanded={menuVisible}
          isNotificationsExpanded={notificationsVisible}
          onToggleMenu={toggleMenu}
          onToggleNotifications={toggleNotifications}
        />

        <SidebarMenu onClose={closeMenu} open={menuVisible} />
        <SidebarNotifications
          onClose={closeNotifications}
          open={notificationsVisible}
        />
        <Content ref={rootLayoutElement}>{children}</Content>
      </Box>

      <FreeVersionToast />
      <Serena />
    </RootLayoutProvider>
  ) : (
    <Box minHeight="100vh">{children}</Box>
  );
};

const FreeVersionToast: FC = () => {
  const { subscription } = useSubscriptionProvider();
  const [showFreeVersion, setShowFreeVersion] = useState(false);
  const [manualHidden, setManualHidden] = useState(
    sessionStorage.getItem("free-version-toast-hidden") === "1"
  );
  const page = useActivePage();
  const location = useLocation();

  const { open, visible, close } = useDialogController();

  const handleHiddenFreeVersion = useCallback(() => {
    sessionStorage.setItem("free-version-toast-hidden", "1");
    setManualHidden(true);
  }, []);

  useEffect(() => {
    const isFree = subscription?.subscription?.plan.id === "FREE";
    if (!manualHidden && isFree && page[0] !== "home") {
      setTimeout(() => setShowFreeVersion(true), 2000);
    } else {
      setShowFreeVersion(false);
    }
  }, [subscription, page, manualHidden]);

  useEffect(() => {
    close();
  }, [location, close]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        px: 2,
        gap: 2,
        borderRadius: 0,
        position: "fixed",
        bottom: showFreeVersion ? 0 : -150,
        left: 0,
        py: 1,
        width: "100vw",
        zIndex: 1000,
        backgroundColor: (theme) => theme.palette.secondary.light,
        transition: (theme) =>
          theme.transitions.create(["bottom"], { duration: "0.5s" }),
      }}
    >
      <Typography
        textAlign="center"
        color={(theme) => theme.palette.secondary.contrastText}
      >
        Estás usando una versión gratuita del software.
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
        }}
      >
        <Button color="primary" variant="contained" size="small" onClick={open}>
          Configura tu suscripción
        </Button>
        <Button
          color="secondary"
          variant="contained"
          size="small"
          onClick={handleHiddenFreeVersion}
        >
          Más tarde
        </Button>
      </Box>
      <Dialog open={visible} onClose={close} maxWidth="md" fullWidth>
        <DialogContent dividers>
          <InitialSteps />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={close}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const Content = styled(Box)(({ theme }) => ({
  paddingTop: HEADER_HEIGHT,
  display: "flex",
  flex: 1,
  flexDirection: "column",
  minHeight: "100vh",
  backgroundColor: "#f5f5f5",
  paddingBottom: 100,
  [theme.breakpoints.up("lg")]: {
    paddingLeft: SIDEBAR_WIDTH,
    height: "100vh",
    overflow: "auto",
    ...customScrollbar,
  },
}));
