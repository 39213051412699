import { PropertyType } from "@serviestate/shared/types";
import { FC } from "react";

export const IconPropertyType: FC<{
  propertyType: PropertyType;
  width?: string | number;
  height?: string | number;
}> = ({ propertyType, width, height }) => {
  return (
    <img
      src={`/images/icons/property-types/${propertyType.id}.svg`}
      alt={`Icono ${propertyType.name}`}
      style={{ width, height }}
    />
  );
};
