import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "qs";

export function useQueryString() {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const query = React.useMemo(() => {
    return qs.parse(search, { ignoreQueryPrefix: true });
  }, [search]);

  const append = React.useCallback(
    (name: string, value: string) => {
      const newQS = {
        ...query,
      };

      if (value === "") {
        delete newQS[name];
      } else {
        newQS[name] = value;
      }

      navigate(`${pathname}?${qs.stringify(newQS)}`);
    },
    [pathname, query, navigate]
  );

  const appendMultiple = React.useCallback(
    (params: { name: string; value: string | undefined | null }[]) => {
      const newQS = {
        ...query,
      };

      for (const { name, value } of params) {
        if (value === "" || value === null) {
          delete newQS[name];
        } else if (value !== undefined) {
          newQS[name] = value;
        }
      }

      navigate(`${pathname}?${qs.stringify(newQS)}`);
    },
    [pathname, query, navigate]
  );

  return {
    search: (search ?? "?").substring(1),
    query,
    append,
    appendMultiple,
  };
}
