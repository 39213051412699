import { useState, useCallback } from "react";

export function useDialogController() {
  const [visible, setVisible] = useState(false);
  const toggle = useCallback(() => setVisible((visible) => !visible), []);
  const close = useCallback(() => setVisible(false), []);
  const open = useCallback(() => setVisible(true), []);

  return {
    visible,
    toggle,
    close,
    open,
  };
}
