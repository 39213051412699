import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import locale_es from "dayjs/locale/es";
import dayjsCustomParserFormatPlugin from "dayjs/plugin/customParseFormat";
import dayjsUtcPlugin from "dayjs/plugin/utc";
import dayjsTimeZone from "dayjs/plugin/timezone";

dayjs.extend(relativeTime);
dayjs.extend(dayjsCustomParserFormatPlugin);
dayjs.extend(dayjsUtcPlugin);
dayjs.extend(dayjsTimeZone);

dayjs.locale(locale_es);

export default dayjs;
