import { Routes, Route } from "react-router-dom";
import { ErrorNotFound } from "../../templates/error-not-found";
import PaymentConfirmationPage from "./payment-confirmation";
import PaymentCancelledPage from "./payment-cancelled";

export default function PaymentPage() {
  return (
    <Routes>
      <Route path="confirmation" element={<PaymentConfirmationPage />} />
      <Route path="cancelled" element={<PaymentCancelledPage />} />
      <Route path="*" element={<ErrorNotFound />} />
    </Routes>
  );
}
