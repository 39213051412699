import { useCallback, useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";

type PromiseFn<T, P> = (...params: P[]) => Promise<T>;

export type Fetcher<T extends (...args: any[]) => Promise<any>> = (
  ...args: Parameters<T>
) => ReturnType<T>;

export type FetchControllerOptions = {
  autoload: boolean;
  displayErrors: boolean;
  onError: (error: Error) => void;
};

export function useFetchController<T, P>(
  fetcher: PromiseFn<T, P>,
  options?: Partial<FetchControllerOptions>
) {
  const optionsRef = useRef(options ?? {});
  const snackbar = useSnackbar();
  const enqueueSnackbar = snackbar?.enqueueSnackbar ?? null;
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(options?.autoload ?? false);

  const autoload = options?.autoload === true;

  const fetchData = useCallback(
    async (...args: P[]) => {
      const displayErrors =
        !!enqueueSnackbar &&
        (optionsRef.current.displayErrors === undefined ||
          optionsRef.current.displayErrors === true);

      setLoading(true);
      try {
        const data = await fetcher(...args);
        setData(data);
        return data;
      } catch (err) {
        if (err instanceof Error) {
          setError(err);
          if (enqueueSnackbar && displayErrors)
            enqueueSnackbar(err.message, { variant: "error" });
        } else {
          let message = `Unknown error: ${err}`;
          let error = new Error(message);
          setError(error);
          if (enqueueSnackbar && displayErrors)
            enqueueSnackbar(message, { variant: "error" });
        }
        if (optionsRef.current.onError)
          optionsRef.current.onError(err as Error);
      } finally {
        setLoading(false);
      }
    },
    [fetcher, enqueueSnackbar]
  );

  const clearData = useCallback(() => {
    setLoading(false);
    setData(null);
    setError(null);
  }, []);

  const replaceData = useCallback((data: T | null) => {
    setData(data);
  }, []);

  useEffect(() => {
    if (autoload) fetchData();
  }, [autoload, fetchData]);

  return { data, error, loading, fetchData, clearData, replaceData };
}
