import React, { FC, PropsWithChildren, useContext, useMemo } from "react";

const RootLayoutContext = React.createContext<{
  element: React.RefObject<HTMLDivElement>;
  scrollToBottom: () => void;
}>({} as any);

export const RootLayoutProvider: FC<
  PropsWithChildren<{ element: React.RefObject<HTMLDivElement> }>
> = ({ children, element }) => {
  const state = useMemo(() => {
    return {
      element,
      scrollToBottom: () => {
        if (element.current) {
          element.current.scroll({
            top: 1000000,
            behavior: "smooth",
          });
        }
      },
    };
  }, [element]);

  return (
    <RootLayoutContext.Provider value={state}>
      {children}
    </RootLayoutContext.Provider>
  );
};

export const useRootLayout = () => useContext(RootLayoutContext);
