import { Typography, styled } from "@mui/material";
import { FC } from "react";
import { useAgencyProvider } from "../../providers/agency.provider";
import { getContentUrl } from "../../utils";

export type LogoAgencyProps = {
  className?: string;
};

export const LogoAgency: FC<LogoAgencyProps> = ({ className }) => {
  const { agency } = useAgencyProvider();
  const logotype = agency?.assets?.find(
    (x) => x.asset_type_code === "logotype"
  );

  if (!agency) return null;

  return (
    <Anchor className={className} href={"/"}>
      {!logotype && (
        <Typography
          component="span"
          fontWeight="700"
          fontSize={22}
          noWrap
          textOverflow="ellipsis"
        >
          {agency?.comercial_name}
        </Typography>
      )}
      {logotype && (
        <Image
          alt={agency.comercial_name}
          src={getContentUrl(logotype?.document_id ?? "")}
        />
      )}
    </Anchor>
  );
};

const Anchor = styled("a")(({ theme }) => ({
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.up("lg")]: {
    justifyContent: "flex-start",
  },
}));

const Image = styled("img")(({ theme }) => ({
  width: "auto",
  height: "auto",
  maxWidth: 200,
  maxHeight: 48,
  [theme.breakpoints.up("lg")]: {
    maxWidth: 200,
    maxHeight: 54,
  },
}));
