import { Agency } from "@serviestate/shared/types";
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAuthProvider } from "./auth.provider";

const AgencyContext = React.createContext<{
  agency: Agency | null;
  refresh: () => Promise<Agency | null>;
}>({ agency: null, refresh: () => Promise.resolve(null) });

export const AgencyProvider: FC<PropsWithChildren> = ({ children }) => {
  const [agency, setAgency] = useState<Agency | null>(null);
  const [{ api, jwt_data }] = useAuthProvider();

  const handleRefresh = useCallback(async () => {
    if (jwt_data?.agency_id) {
      return api.agencies
        .getActive()
        .then((agency) => {
          setAgency(agency);
          return agency;
        })
        .catch((e) => {
          console.error(e);
          return null;
        });
    }
    return null;
  }, [jwt_data, api]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  const state = useMemo(
    () => ({ agency, refresh: handleRefresh }),
    [agency, handleRefresh]
  );

  return (
    <AgencyContext.Provider value={state}>{children}</AgencyContext.Provider>
  );
};

export const useAgencyProvider = () => useContext(AgencyContext);
