import {
  Box,
  Hidden,
  Typography,
  Stack,
  Chip,
  Alert,
  Skeleton,
} from "@mui/material";
import { Contact } from "@serviestate/shared";
import { FC, useCallback } from "react";
import { UserAvatar } from "../../atoms/user-avatar";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import PhoneIcon from "@mui/icons-material/PhoneOutlined";
import { useAPI } from "../../../hooks/use-api";
import { useFetchController } from "@serviestate/react-ui";

export type ContactCardProps = {
  contact: Contact;
  onClick?: (contact: Contact) => void;
};
export const ContactCard: FC<ContactCardProps> = ({ contact, onClick }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap={2}
      sx={{ cursor: onClick ? "pointer" : "unset" }}
      onClick={() => onClick && onClick(contact)}
    >
      <Box>
        <UserAvatar username={contact.firstname} />
      </Box>
      <Box>
        <Hidden smDown>
          <Typography gutterBottom={false}>{contact.display_name}</Typography>
        </Hidden>
        <Hidden smUp>
          <Typography gutterBottom={false} variant="h6">
            {contact.display_name}
          </Typography>
        </Hidden>
        <Box display="flex" flexDirection="column" gap={0.75}>
          <Hidden smUp>
            {contact.email && contact.email.length > 0 && (
              <Typography display="flex" alignItems="center" gap={0.5}>
                <EmailIcon fontSize="small" />
                {contact.email}
              </Typography>
            )}
            {contact.mobile && contact.mobile.length > 0 && (
              <Typography display="flex" alignItems="center" gap={0.5}>
                <PhoneIcon fontSize="small" />
                {contact.mobile}
              </Typography>
            )}
            {contact.tags && contact.tags.length > 0 && (
              <Stack direction="row" spacing={1}>
                {contact.tags.map((x) => (
                  <Chip size="small" label={x} />
                ))}
              </Stack>
            )}
          </Hidden>
        </Box>
      </Box>
    </Box>
  );
};

export const ContactCardRemote: FC<
  Omit<ContactCardProps, "contact"> & { contactId: string }
> = ({ contactId, ...rest }) => {
  const api = useAPI();

  const fetchContact = useCallback(
    () => api.contacts.detail(contactId),
    [api, contactId]
  );

  const { data: contact, loading } = useFetchController(fetchContact, {
    autoload: true,
    displayErrors: false,
  });

  if (loading) {
    return (
      <Box display="flex" gap={2} alignItems="center" justifyContent="center">
        <Skeleton variant="circular" width={40} height={40} />
        <Box flex={1}>
          <Skeleton animation="wave" />
          <Hidden smUp>
            <Skeleton animation="wave" />
          </Hidden>
        </Box>
      </Box>
    );
  }

  if (!contact) {
    return (
      <Alert color="error" icon={null}>
        Error loading contact
      </Alert>
    );
  }

  return <ContactCard contact={contact} {...rest} />;
};
