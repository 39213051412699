export function canAccess(path, grantPermissions, denyPermissions) {
    const pathParts = path.split(".");
    const permissions = [
        ...grantPermissions.map((x) => ({
            type: "grant",
            name: x.replace(".*", ""),
            is_wildcard: x.endsWith(".*") || x === "*",
        })),
        ...denyPermissions.map((x) => ({
            type: "deny",
            name: x.replace(".*", ""),
            is_wildcard: x.endsWith(".*") || x === "*",
        })),
    ]
        .map((x) => ({
        ...x,
        parts: x.name.split("."),
        granularity: x.name.split(".").length,
    }))
        .sort((a, b) => {
        const diff = b.parts.length - a.parts.length;
        if (diff === 0) {
            return a.type === "deny" ? -1 : 1;
        }
        return diff;
    });
    let result = false;
    for (let i = 1; i <= pathParts.length; i++) {
        const currentPath = pathParts.slice(0, i).join(".");
        const matchingPermissions = permissions.filter((perm) => {
            if (perm.is_wildcard && i === perm.granularity) {
                return currentPath.startsWith(perm.name) || perm.name === "*";
            }
            return perm.name === currentPath;
        });
        if (matchingPermissions.some((perm) => perm.type === "deny")) {
            result = false;
        }
        else {
            result = true;
        }
    }
    return result;
}
