import { Box, Typography, Hidden, Stack, Chip } from "@mui/material";
import { Contact } from "@serviestate/shared/types";
import { FC } from "react";
import { UserAvatar } from "../atoms/user-avatar";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import PhoneIcon from "@mui/icons-material/PhoneOutlined";
import { TableCellNoWrap } from "../atoms/table-cell-nowrap";

export type TableContactCellProps = {
  contact: Contact;
};

export const TableContactCell: FC<TableContactCellProps> = ({ contact }) => {
  return (
    <TableCellNoWrap>
      <Box display="flex" alignItems="center" gap={2}>
        <Box>
          <UserAvatar username={contact.firstname} />
        </Box>
        <Box>
          <Hidden smDown>
            <Typography gutterBottom={false}>{contact.display_name}</Typography>
          </Hidden>
          <Hidden smUp>
            <Typography gutterBottom={false} variant="h6">
              {contact.display_name}
            </Typography>
          </Hidden>
          <Box display="flex" flexDirection="column" gap={0.75}>
            <Hidden smUp>
              {contact.email && contact.email.length > 0 && (
                <Typography display="flex" alignItems="center" gap={0.5}>
                  <EmailIcon fontSize="small" />
                  {contact.email}
                </Typography>
              )}
              {contact.mobile && contact.mobile.length > 0 && (
                <Typography display="flex" alignItems="center" gap={0.5}>
                  <PhoneIcon fontSize="small" />
                  {contact.mobile}
                </Typography>
              )}
              {contact.tags && contact.tags.length > 0 && (
                <Stack direction="row" spacing={1}>
                  {contact.tags.map((x) => (
                    <Chip size="small" label={x} />
                  ))}
                </Stack>
              )}
            </Hidden>
          </Box>
        </Box>
      </Box>
    </TableCellNoWrap>
  );
};
