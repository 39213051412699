import { Box, LinearProgress, Link, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useQueryString } from "../../../hooks/use-query-string";
import { useAPI } from "../../../hooks/use-api";
import { PaymentLayout } from "./_layout";
import { useSubscriptionProvider } from "../../../providers/subscription.provider";

export default function PaymentConfirmationPage() {
  const api = useAPI();
  const [state, setState] = useState("verifying");
  const { query } = useQueryString();
  const { subscription, refresh } = useSubscriptionProvider();

  useEffect(() => {
    const reference = query.reference;
    if (!reference) {
      setState("provider_error");
      return;
    }
    (async () => {
      const params = { ...query };
      delete params.reference;

      const paymentVerification = await api.payments.confirmation({
        reference: reference as any,
        params: params as any,
      });

      async function checkSubscription() {
        const sub = await refresh();
        if (!sub || sub.state === "unavailable") {
          await new Promise((resolve, reject) => {
            setTimeout(() => {
              checkSubscription().then(resolve).catch(reject);
            }, 500);
          });
        }
      }

      setState(paymentVerification.payment_status_code);
      checkSubscription();
    })();
  }, [api, query, refresh]);

  if (state === "success") {
    return (
      <PaymentLayout>
        <Typography>¡¡¡Pago completado!!!</Typography>
        {subscription?.state === "unavailable" ? (
          <Box>
            <Typography>
              Estamos activando tu suscripción, será un momento.
            </Typography>
            <LinearProgress />
          </Box>
        ) : (
          <Link href="/">¡Listo! Ya puedes ir al CRM</Link>
        )}
      </PaymentLayout>
    );
  }

  if (state === "verifying") {
    return <PaymentLayout>Verificando el pago</PaymentLayout>;
  }

  return (
    <PaymentLayout>
      <Box>
        Error de la pasarela de pago, por favor, pongase en contacto con
        nosotros a través de la dirección&nbsp;
        <a href="mailto:soporte@serviestate.com">soporte@serviestate.com</a>
        <Typography>Código del error: {state}</Typography>
      </Box>
    </PaymentLayout>
  );
}
