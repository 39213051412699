import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  FormHelperText,
} from "@mui/material";
import useId from "@mui/material/utils/useId";
import React from "react";
import {MdClear} from "react-icons/md";
import { useFetchController } from "../hooks";

export type RemoteSelectProps<TItem, TId = string | number> = {
  id?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  idFieldItem: (item: TItem) => TId;
  labelFieldItem: (item: TItem) => string;
  value?: string | number | null;
  error?: boolean;
  helperText?: string;
  fetcher: () => Promise<TItem[]>;
  onChange?: (id: TId) => void;
  onClear?: () => void;
  autoFocus?: boolean;
  fullWidth?: boolean;

  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  required?: boolean;
  disabled?: boolean;
  size?: "small" | "medium";
};

function RemoteSelectInner<TItem, TId = string | number>(
  {
    fullWidth,
    id,
    idFieldItem,
    labelFieldItem,
    label,
    placeholder,
    onChange,
    fetcher,
    autoFocus,
    name,
    onBlur,
    disabled,
    required,
    value,
    size,
    error,
    helperText,
    onClear,
  }: RemoteSelectProps<TItem, TId>,
  ref: React.ForwardedRef<unknown>
) {
  const idField = useId(id);

  const { loading, data: items } = useFetchController(fetcher, {
    autoload: true,
  });

  const handleChange = React.useCallback(
    (e: any) => {
      if (onChange && items) {
        onChange(e.target.value as unknown as TId);
      }
    },
    [onChange, items]
  );

  return (
    <FormControl
      fullWidth={fullWidth}
      disabled={loading}
      size={size}
      required={required}
      error={error}
      sx={{ minWidth: fullWidth ? "100%" : "180px" }}
    >
      <InputLabel id={`label-${idField}`} disabled={disabled} error={error}>
        {label}
      </InputLabel>
      <Select
        ref={ref}
        name={name}
        disabled={disabled}
        autoFocus={autoFocus}
        id={idField}
        labelId={`label-${idField}`}
        label={label}
        onChange={handleChange}
        onBlur={onBlur}
        value={(value as unknown as string) ?? ""}
        placeholder={placeholder}
        error={!!error}
        endAdornment={
          onClear &&
          !disabled && (
            <IconButton sx={{ display: value ? "" : "none" }} onClick={onClear}>
              <MdClear />
            </IconButton>
          )
        }
      >
        {!required && (
          <MenuItem value="">
            <em>Sin especificar</em>
          </MenuItem>
        )}
        {items?.map((item) => {
          const id = idFieldItem(item);
          const label = labelFieldItem(item);
          return (
            <MenuItem key={`option-${id}`} value={id as string}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

// WA: We need redefine type becouse forwardedRef not export generic types...
export const RemoteSelect = React.forwardRef(RemoteSelectInner) as unknown as <
  TItem,
  TId = string | number
>(
  props: RemoteSelectProps<TItem, TId> & {
    ref?: React.ForwardedRef<unknown>;
  }
) => ReturnType<typeof RemoteSelectInner>;
