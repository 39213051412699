import {
  Alert,
  Avatar,
  Box,
  Fab,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  TextField,
  Typography,
  keyframes,
} from "@mui/material";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { IoSendSharp } from "react-icons/io5";
import { useAPI } from "../../hooks/use-api";
import { useFetchController } from "@serviestate/react-ui";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import { PropertyCardRemote } from "./property-card";
import { ContactCardRemote } from "./cards/contact-card";
import { useNavigate } from "react-router-dom";
import { ExternalPropertyCardRemote } from "./cards/external-property-card";
import ReactDOMServer from "react-dom/server";
import CloseIcon from "@mui/icons-material/Close";

export const Serena: FC = () => {
  const [openChat, setOpenChat] = useState(false);

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          transition: "bottom 0.5s ease-in-out",
          bottom: openChat ? -100 : 30,
          right: 50,
          zIndex: 1000,
          borderRadius: "50%",
          background: "#ffffff",
        }}
      >
        <Fab
          onClick={() => setOpenChat(true)}
          sx={{
            width: { xs: 60, md: 80 },
            height: { xs: 60, md: 80 },
            "&::after": {
              backgroundImage:
                "linear-gradient(to bottom, #336e99 0%, #6ff1c7 100%);",
              content: "''",
              position: "absolute",
              top: "-5px",
              bottom: "-5px",
              right: "-5px",
              left: "-5px",
              zIndex: -1,
              borderRadius: "inherit",
              animation: `${spin} 5s linear infinite`,
            },
            "&:hover::after": {
              animation: `${spin} 1s linear infinite`,
            },
          }}
        >
          <Avatar
            variant="circular"
            src="./images/serena_avatar.png"
            sx={{
              width: "100%",
              height: "100%",
            }}
          />
        </Fab>
      </Box>
      <SerenaChat open={openChat} onClose={() => setOpenChat(false)} />
    </>
  );
};

export type SerenaChatProps = { open: boolean; onClose: () => void };

export const SerenaChat: FC<SerenaChatProps> = ({ open, onClose }) => {
  const api = useAPI();
  const listRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [isSending, setIsSending] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [chatId, setChatId] = useState<string | null>(null);
  const [loadingSerena, setLoadingSerena] = useState(false);
  const [errorLoadingSerena, setErrorLoadingSerena] = useState(false);
  const [playVoice, setPlayVoice] = useState(false);
  const [playedAssistantMessages, setPlayedAssistantMessages] = useState<
    string[]
  >([]);

  const fetchChat = useCallback(
    async () => (chatId ? await api.serena.getAgencyUserChat(chatId) : null),
    [api, chatId]
  );

  const {
    data: chat,
    loading,
    error,
    fetchData,
    replaceData,
  } = useFetchController(fetchChat, {
    autoload: true,
  });

  const renderMessageContent = useCallback(
    (content: string) => {
      const regex = /\[(property|contact|acquisition):(.+?)\]/g;
      let match: RegExpExecArray | null;

      // Fragmentos del contenido del mensaje
      const parts: JSX.Element[] = [];

      // Última posición después del último match procesado
      let lastIndex = 0;

      // Buscar todos los matches en el contenido
      while ((match = regex.exec(content)) !== null) {
        // Agregar texto antes del match actual
        const textBefore = content.slice(lastIndex, match.index);
        lastIndex = regex.lastIndex;

        if (textBefore) {
          parts.push(
            <ReactMarkdown
              key={lastIndex + "-before"}
              remarkPlugins={[gfm]}
              components={{
                a: (props) => {
                  let href = props.href?.replace(/&amp;/g, "&");
                  const isCRM =
                    href?.indexOf("https://crm.serviestate.com") === 0;
                  href = isCRM
                    ? href?.replace("https://crm.serviestate.com", "")
                    : href;

                  return (
                    <Link
                      target={isCRM ? "_self" : "_blank"}
                      referrerPolicy="no-referrer"
                      href={href}
                    >
                      {props.children}
                    </Link>
                  );
                },
              }}
              children={textBefore}
            />
          );
        }

        // Destructurar el resultado del match
        const [, type, id] = match;

        // Según el tipo, insertar el componente correspondiente
        switch (type) {
          case "property":
            parts.push(
              <Box key={id} sx={{ my: 1 }}>
                <PropertyCardRemote
                  propertyId={id}
                  onClick={(x) => navigate(`/properties/${x.id}`)}
                />
              </Box>
            );
            break;
          case "contact":
            parts.push(
              <Box key={id} sx={{ my: 1 }}>
                <ContactCardRemote
                  contactId={id}
                  onClick={(x) => navigate(`/contacts/${x.id}`)}
                />
              </Box>
            );
            break;
          case "acquisition":
            parts.push(
              <Box key={id} sx={{ my: 1 }}>
                <ExternalPropertyCardRemote
                  externalPropertyId={id}
                  onClick={(x) => navigate(`/acquisition/news/${x.id}`)}
                />
              </Box>
            );
            break;
          case "document":
            parts.push(
              <a
                key={id}
                href={`/api/documents/${id}/content`}
                target="_blank"
                rel="noreferrer"
              >
                (Ver documento)
              </a>
            );
            break;
        }

        // Considera añadir más condiciones aquí para otros tipos
      }

      // Agregar cualquier texto restante después del último match
      const textAfter = content.slice(lastIndex);
      if (textAfter) {
        parts.push(
          <ReactMarkdown
            key={lastIndex + "-after"}
            remarkPlugins={[gfm]}
            children={textAfter}
            components={{
              a: (props) => {
                let href = props.href?.replace(/&amp;/g, "&");
                const isCRM =
                  href?.indexOf("https://crm.serviestate.com") === 0;
                href = isCRM
                  ? href?.replace("https://crm.serviestate.com", "")
                  : href;

                return (
                  <Link
                    target={isCRM ? "_self" : "_blank"}
                    referrerPolicy="no-referrer"
                    href={href}
                  >
                    {props.children}
                  </Link>
                );
              },
            }}
          />
        );
      }

      return parts;
    },
    [navigate]
  );

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [chat]);

  useEffect(() => {
    const voices = window.speechSynthesis
      .getVoices()
      .filter((x) => x.lang.indexOf("ES") >= 0);

    const selectedVoice =
      voices.filter(
        (x) =>
          x.voiceURI === "Microsoft Elvira Online (Natural) - Spanish (Spain)"
      )[0] ?? voices[0];

    for (const message of chat?.messages || []) {
      if (
        message.role === "assistant" &&
        !playedAssistantMessages.includes(message.id)
      ) {
        if (playVoice) {
          const renderedContent = renderMessageContent(message.content);
          // convert JSX.Element[] into html string
          const html = ReactDOMServer.renderToStaticMarkup(
            <>{renderedContent}</>
          );
          const divElement = document.createElement("div");
          divElement.innerHTML = html;
          const text = divElement.innerText;

          const utterance = new SpeechSynthesisUtterance(text);
          utterance.voice = selectedVoice;
          utterance.rate = 1.2;
          window.speechSynthesis.speak(utterance);
        }
        setPlayedAssistantMessages([...playedAssistantMessages, message.id]);
      }
    }
  }, [chat, playVoice, playedAssistantMessages, renderMessageContent]);

  useEffect(() => {
    if (!playVoice) window.speechSynthesis.cancel();
  }, [playVoice]);

  useEffect(() => {
    if (open && chatId === null) {
      setLoadingSerena(true);
      api.serena
        .startConversation("crm")
        .then((chatId) => setChatId(chatId))
        .catch(() => setErrorLoadingSerena(true))
        .finally(() => setLoadingSerena(false));
    }
  }, [open, api, chatId]);

  // useEffect(() => {
  //   if (!open && chatId) api.serena.endConversation(chatId);
  // }, [chatId, open, api]);

  return (
    <Paper
      elevation={10}
      sx={{
        position: "fixed",
        transition: "all 0.5s ease-in-out",
        bottom: {
          xs: open ? 0 : "100vh",
          md: open ? 16 : -550,
          lg: open ? 16 : -750,
          xl: open ? 16 : -850,
        },
        right: {
          xs: open ? 0 : "100vw",
          md: open ? 16 : -450,
          lg: open ? 16 : -650,
          xl: open ? 16 : -750,
        },
        maxWidth: { xs: "100vw", md: 400, lg: 600, xl: 700 },
        width: "100%",
        maxHeight: { xs: "100vh", md: 500, lg: 700, xl: 800 },
        height: "100%",
        // backgroundColor: "#fff",
        // border: { md: "3px solid #e0e0e0" },
        // borderRadius: { md: "1rem" },
        zIndex: 1299,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.5rem",
          borderBottom: "1px solid #e0e0e0",
        }}
      >
        <Box>
          <Avatar
            variant="circular"
            src="./images/serena_avatar.png"
            sx={{
              width: "40px",
              height: "40px",
            }}
          />
        </Box>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "1.2rem",
          }}
        >
          Serena
        </Typography>
        {/* <IconButton onClick={() => setPlayVoice(!playVoice)}>
          {playVoice ? <MdOutlineRecordVoiceOver /> : <MdOutlineVoiceOverOff />}
        </IconButton> */}
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          overflowX: "hidden",
          overflowY: "scroll",
          px: 1,
          py: 1,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
        ref={listRef}
      >
        {loadingSerena ||
          (loading && (
            <Box>
              Cargando chat con Serena...
              <LinearProgress />
            </Box>
          ))}
        {(error || errorLoadingSerena) && (
          <Alert icon={null} color="error">
            Ha ocurrido un error al cargar el chat con Serena, intentalo más
            tarde.
          </Alert>
        )}
        {chat?.messages.map((message) => (
          <Box
            key={message.id}
            sx={{
              display: "flex",
              justifyContent:
                message.role === "assistant" ? "flex-start" : "flex-end",
            }}
          >
            <Box
              sx={{
                display: "inline-block",
                position: "relative",
                color: "#fff",
                backgroundColor:
                  message.role === "assistant" ? "#353535" : "#005c4b",
                maxWidth: "90%",
                borderRadius: "0.5rem",
                minWidth: "100px",
                padding: "0.5rem",
                overflowX: "auto",
                "& p": {
                  m: 0,
                },
                "& table": {
                  width: "100%",
                  borderCollapse: "collapse",
                  my: "0.5rem",
                  "& th, td": {
                    border: "1px solid #e0e0e0",
                    padding: "0.25rem 0.5rem",
                    whiteSpace: "nowrap",
                  },
                },
              }}
            >
              {renderMessageContent(message.content)}
            </Box>
          </Box>
        ))}
      </Box>

      <Box
        sx={{
          position: "relative",
          display: "flex",
          borderTop: "1px solid #e0e0e0",
          alignItems: "center",
          px: 1,
        }}
      >
        {isSending && (
          <Box sx={{ position: "absolute", top: "-20px", left: "25px" }}>
            <div className="dot-elastic"></div>
          </Box>
        )}
        <TextField
          fullWidth
          variant="standard"
          placeholder="Escribe un mensaje..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              sendMessage();
            }
          }}
          disabled={isSending}
        />
        <IconButton color="primary" onClick={sendMessage} disabled={isSending}>
          <IoSendSharp />
        </IconButton>
      </Box>
    </Paper>
  );

  async function sendMessage() {
    if (!chat) return;
    if (newMessage.trim() !== "") {
      replaceData({
        ...chat,
        messages: [
          ...chat.messages,
          {
            id: "new",
            channel: "crm",
            content: newMessage,
            created_at: new Date(),
            role: "user",
            openai_usage: null,
            serena_chat_id: chat.id,
            status: "pending",
          },
        ],
      });

      setIsSending(true);
      setNewMessage(""); // Limpiar el campo de entrada después de enviar

      await api.serena.sendMessage({
        chat_id: chat.id,
        channel: "crm",
        content: newMessage,
      });

      setIsSending(false);

      await fetchData();
    }
  }
};

const spin = keyframes`
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
`;
