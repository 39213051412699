import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./Fonts";
import "./fonts.css";
import "./index.css";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material";
import serviestateTheme from "./ServiestateTheme";
import "./dayjs";
import "./datepicker.register";
import { loadRecaptcha } from "./recaptcha-loader";
import { ServiestateApi } from "@serviestate/sdk";
import config from "./config";
import * as pdfjs from "pdfjs-dist";
import "./plausible";

function startApp() {
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  loadRecaptcha().then(
    () =>
      (ServiestateApi.recaptchaTokenResolver = (action) =>
        window.grecaptcha.execute(config.RECAPTCHA_KEY, { action }))
  );

  const container = document.getElementById("root");
  if (!container) throw new Error(`root element not found`);
  const root = createRoot(container);

  root.render(
    <ThemeProvider theme={serviestateTheme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  );

  reportWebVitals();
}

if (config.IS_CORDOVA) {
  document.addEventListener("deviceready", startApp, false);
} else {
  startApp();
}
