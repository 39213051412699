import { Channel } from "@serviestate/shared";

export interface PortalImageProps {
  size?: number;
  channel: Channel;
}
export function PortalImage({ size, channel }: PortalImageProps) {
  return (
    <img
      height={size ?? 80}
      width={size ?? 80}
      alt={channel.name}
      src={`/images/portals/circle/${channel.code}.webp`}
    ></img>
  );
}
