import qs from "qs";
import { Periodicity } from "@serviestate/shared/types";
import config from "./config";

export type ContentUrlProps = {
  fit?: "cover" | "contain" | "inside" | "outside";
  width?: number;
  height?: number;
  quality?: number;
  format?: "jpg" | "png" | "webp" | "auto";
};

export function getContentUrl(id: string, props?: ContentUrlProps): string {
  if (config.API_ENDPOINT.indexOf("http") === 0) {
    const uri = new URL(
      `documents/${id}/content?${qs.stringify(props ?? {})}`,
      config.API_ENDPOINT
    );
    return uri.toString();
  } else {
    return `${config.API_ENDPOINT}/documents/${id}/content?${qs.stringify(
      props ?? {}
    )}`.replace(/\/+/gim, "/");
  }
}

export function formatNumber(value: number | undefined) {
  if (!value) return "";
  const formatter = new Intl.NumberFormat("de-DE", {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  return formatter.format(value);
}

export function formatCurrency(value: number | undefined) {
  if (!value) return "Consultar";
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 0,
  }).format(value);
}

export function formatPeriodicity(periodicity: Periodicity | null): string {
  if (!periodicity) return "";

  switch (periodicity) {
    case "YEARLY":
      return "/anual";
    case "BIYEARLY":
      return "/bianual";
    case "QUARTERLY":
      return "/trimestral";
    case "MONTHLY":
      return "/mensual";
    case "WEEKLY":
      return "/semanal";
    default:
      return "";
  }
}

function hashCode(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function intToRGB(i: number) {
  const c = (i & 0x00ffffff).toString(16).toUpperCase();
  return "#" + "00000".substring(0, 6 - c.length) + c;
}

export function getPastelColor(text: string) {
  return intToRGB(hashCode(text));
}

export function getContrastColor(hexColor: string) {
  if (hexColor.indexOf("#") === 0) {
    hexColor = hexColor.slice(1);
  }

  // Convertir a valores RGB
  var r = parseInt(hexColor.substring(0, 2), 16);
  var g = parseInt(hexColor.substring(2, 4), 16);
  var b = parseInt(hexColor.substring(4, 6), 16);

  // Calcular la luminancia del color
  var luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Determinar si el color es claro u oscuro
  if (luminance > 0.5) {
    return "#000000"; // Negro para fondos claros
  } else {
    return "#FFFFFF"; // Blanco para fondos oscuros
  }
}
