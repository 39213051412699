const configInjected = (window as any).config as
  | { api_endpoint?: string }
  | undefined;

const config = {
  IS_CORDOVA: !!(window as any)?.cordova,
  RECAPTCHA_KEY: "6Lfak2ojAAAAACRplaEtRB0VKumpflxQt-AC1oUH",
  API_ENDPOINT: configInjected?.api_endpoint ?? "/api",
};

export default config;
