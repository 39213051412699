import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from "react";
import { ConfirmationDialog } from "../components/organisms/dialogs/confirmation-dialog";

type ConfirmationDialogType = {
  dialogs: DialogProps[];
  enqueueConfirmation: (props: DialogProps) => void;
};

export const ConfirmationDialogContext =
  React.createContext<ConfirmationDialogType>({
    dialogs: [],
    enqueueConfirmation: () => {},
  });

type DialogPayloadConfirm = { option: string | null; text: string };

type DialogProps = {
  onCancel?: () => void;
  onConfirm?: (payload: DialogPayloadConfirm) => void;
  title: string;
  isDangerConfirmation?: boolean;
  labelNo?: string;
  labelYes?: string;
  content?: React.ReactNode;
  hiddenLabelNo?: boolean;
  options?: { value: string; label: string }[] | null;
  showInput?: false | "singleline" | "multiline";
  defaultInputText?: string;
};

const DialogController: FC<DialogProps> = ({
  onCancel,
  onConfirm,
  title,
  isDangerConfirmation,
  labelNo,
  labelYes,
  content,
  hiddenLabelNo,
  options,
  showInput,
  defaultInputText,
}) => {
  const [open, setOpen] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  const [inputText, setInputText] = useState(defaultInputText ?? "");

  const handleClose = useCallback(() => {
    setOpen(false);
    onCancel && onCancel();
  }, [onCancel]);

  const handleConfirm = useCallback(() => {
    setOpen(false);
    onConfirm &&
      onConfirm({
        option: selectedOption.length === 0 ? null : selectedOption,
        text: inputText,
      });
  }, [onConfirm, selectedOption, inputText]);

  return (
    <ConfirmationDialog
      open={open}
      onCancel={handleClose}
      onConfirm={handleConfirm}
      title={title}
      isDangerConfirmation={isDangerConfirmation}
      labelNo={labelNo}
      labelYes={labelYes}
      hiddenLabelNo={hiddenLabelNo}
    >
      {content}
      {showInput && (
        <Box sx={{ mt: 1 }}>
          <TextField
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            fullWidth
            multiline={showInput === "multiline"}
            minRows={3}
          />
        </Box>
      )}
      {options && options.length > 0 && (
        <Box>
          <FormControl sx={{ mt: content ? 1 : 0 }}>
            <RadioGroup
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
            >
              {options.map((x) => (
                <FormControlLabel
                  key={`confirmation_option_${x.value}`}
                  value={x.value}
                  control={<Radio />}
                  label={x.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
      )}
    </ConfirmationDialog>
  );
};

export const ConfirmationDialogProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [dialogs, setDialogs] = React.useState<DialogProps[]>([]);

  const enqueue = React.useCallback((props: DialogProps) => {
    setDialogs((old) => [...old, props]);
  }, []);

  const state = useMemo<ConfirmationDialogType>(
    () => ({ dialogs, enqueueConfirmation: enqueue }),
    [dialogs, enqueue]
  );

  const handleDeattach = React.useCallback((x: DialogProps) => {
    setDialogs((old) => old.filter((y) => y !== x));
  }, []);

  const handleConfirm = useCallback(
    (x: DialogProps) => {
      return (payload: DialogPayloadConfirm) => {
        handleDeattach(x);
        x.onConfirm && x.onConfirm(payload);
      };
    },
    [handleDeattach]
  );

  const handleCancel = useCallback(
    (x: DialogProps) => {
      return () => {
        handleDeattach(x);
        x.onCancel && x.onCancel();
      };
    },
    [handleDeattach]
  );

  return (
    <ConfirmationDialogContext.Provider value={state}>
      {children}
      {dialogs.map((x, i) => (
        <DialogController
          key={`cd_${i}`}
          {...x}
          onConfirm={handleConfirm(x)}
          onCancel={handleCancel(x)}
        />
      ))}
    </ConfirmationDialogContext.Provider>
  );
};
