export const resolveTranslation = (item, language = "es") => {
    if (!item)
        return "";
    const keys = Object.keys(item);
    const defaultContent = keys
        .map((x) => item[x])
        .filter((x) => x && x.length > 0)[0];
    if (!language)
        return defaultContent;
    return item[language] ?? defaultContent;
};
