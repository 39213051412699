import { Badge, IconButton } from "@mui/material";
import { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";
import { useNotificationsProvider } from "../../providers/notifications.provider";

export type HeaderNotificationsProps = {
  isNotificationsExpanded: boolean;
  onToggle: () => void;
};

export const HeaderNotifications: FC<HeaderNotificationsProps> = ({
  isNotificationsExpanded,
  onToggle,
}) => {
  const { pendingToBeRead } = useNotificationsProvider();
  return (
    <>
      <IconButton onClick={onToggle}>
        <Badge badgeContent={pendingToBeRead} color="primary">
          {isNotificationsExpanded ? <CloseIcon /> : <NotificationsIcon />}
        </Badge>
      </IconButton>
    </>
  );
};
