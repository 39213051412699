import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  List,
  ListItemText,
  styled,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import dayjs from "dayjs";
import { FC, useCallback } from "react";
import { useAPI } from "../../../hooks/use-api";
import { useFetchController } from "../../../hooks/use-fetch-controller";
import { PDFViewer } from "../../atoms/pdf-viewer";
import { getContentUrl } from "../../../utils";

export type SignatureInfoDialogProps = {
  open: boolean;
  signatureId: string;
  onClose: () => void;
};

export const SignatureInfoDialog: FC<SignatureInfoDialogProps> = ({
  open,
  signatureId,
  onClose,
}) => {
  const api = useAPI();
  const isTablet = useMediaQuery<Theme>((theme) => theme.breakpoints.up("sm"));

  const fetchSignature = useCallback(
    () => api.signatures.detail(signatureId),
    [api, signatureId]
  );

  const { data: signature } = useFetchController(fetchSignature, {
    autoload: true,
  });

  const fetchDocument = useCallback(
    async () =>
      signature ? await api.documents.detail(signature.document_id) : null,
    [api, signature]
  );

  const { data: document } = useFetchController(fetchDocument, {
    autoload: true,
  });

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        scroll="paper"
        fullWidth
        maxWidth={"md"}
        fullScreen={!isTablet}
        aria-labelledby="signature-info-dialog-title"
        aria-describedby="signature-info-dialog-form"
      >
        <DialogTitle
          id="signature-info-dialog-title"
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
        >
          <ImgModalIcon
            src="/images/menu/mensajes.png"
            alt="Icono de mensaje"
          />
          Asistente para la solicitud de firma electrónica
        </DialogTitle>
        {signature && (
          <>
            <DialogContent
              sx={{
                padding: 3,
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
              dividers
              id="start-signature-dialog-form"
            >
              <Typography>Datos</Typography>
              <List>
                <ListItemText>
                  Fecha alta:
                  {dayjs(signature.created_at).format("DD/MM/YYYY HH:mm")}
                </ListItemText>
                <ListItemText>
                  Fecha de firma:
                  {signature.completed_at
                    ? dayjs(signature.completed_at).format("DD/MM/YYYY HH:mm")
                    : "Pendiente"}
                </ListItemText>
                <ListItemText>
                  Solicitada por:
                  {signature.request_by_id}
                </ListItemText>
              </List>
              <Typography>Destinatarios</Typography>
              <List>
                {signature.signers.map((x: any) => (
                  <ListItemText>
                    contact: {x.contact.display_name}
                    {!x.signed_at && (
                      <Link
                        variant="button"
                        href={`/signature-flow?agencyId=${signature.agency_id}&signatureId=${signature.id}&signerId=${x.id}`}
                      >
                        (Iniciar proceso de firmar)
                      </Link>
                    )}
                  </ListItemText>
                ))}
              </List>
              <Typography>
                El documento que se va a firmar es el siguiente:
              </Typography>
              {document && (
                <Box sx={{ height: "calc(100vh - 200px)" }}>
                  <PDFViewer
                    fileName={document.filename}
                    fileUrl={getContentUrl(
                      signature.signed_document_id ?? document.id
                    )}
                  />
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button type="button" color="secondary" onClick={onClose}>
                Cerrar
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

const ImgModalIcon = styled("img")(({ theme }) => ({
  width: 32,
  height: 32,
  display: "block",
}));
