import { UserNotification } from "@serviestate/shared/types";
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuthProvider } from "./auth.provider";
import { useAPI } from "../hooks/use-api";
import { useSnackbar } from "notistack";

export type NotificationsState = {
  pendingToBeRead: number;
  notifications: UserNotification[];
  setAllAsRead: () => void;
};

const NotificationsContext = React.createContext<NotificationsState>({
  pendingToBeRead: 0,
  notifications: [],
  setAllAsRead: () => {},
});

export const NotificationsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [pendingToBeRead, setPendingToBeRead] = useState(0);
  const [notifications, setNotifications] = useState<UserNotification[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const api = useAPI();
  const [{ isAuthenticated }] = useAuthProvider();

  const setAllAsRead = useCallback(async () => {
    const notificationsPendingToRead = notifications
      .filter((x) => !!!x.read_at)
      .map((x) => x.id);

    if (notificationsPendingToRead.length > 0) {
      await api.notifications.markAsRead(notificationsPendingToRead);
      setPendingToBeRead(0);
    }
  }, [api, notifications]);

  useEffect(() => {
    if (!isAuthenticated) return;
    let active = true;
    let pendingToRead = 0;
    let notifications: UserNotification[] = [];

    async function load(isFirstLoad: boolean) {
      if (!active) return;
      try {
        const total = await api.notifications.getNotificationsPendingToRead();
        if (pendingToRead !== total || isFirstLoad) {
          notifications =
            total !== 0 || isFirstLoad
              ? await api.notifications.getNotifications()
              : notifications;
          if (!isFirstLoad && total > 0) {
            enqueueSnackbar("Tienes nuevas notificaciones 👍", {
              variant: "info",
            });
          }
          pendingToRead = total;
          setNotifications(notifications);
          setPendingToBeRead(total);
        }
      } catch (e) {
        console.error(e);
      }
      setTimeout(() => load(false), 5000);
    }

    load(true);

    return () => {
      active = false;
    };
  }, [api, enqueueSnackbar, isAuthenticated]);

  return (
    <NotificationsContext.Provider
      value={{ notifications, pendingToBeRead, setAllAsRead }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotificationsProvider = () => useContext(NotificationsContext);
