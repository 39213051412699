import { SubscriptionActiveResult } from "@serviestate/shared/types";
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAuthProvider } from "./auth.provider";
import { useAgencyProvider } from "./agency.provider";

const SubscriptionContext = React.createContext<{
  subscription: SubscriptionActiveResult | null;
  refresh: () => Promise<SubscriptionActiveResult | null>;
  isFree: boolean;
}>({
  subscription: null,
  refresh: () => Promise.resolve(null),
  isFree: false,
});

export const SubscriptionProvider: FC<PropsWithChildren> = ({ children }) => {
  const [result, setResult] = useState<SubscriptionActiveResult | null>(null);
  const [{ api }] = useAuthProvider();
  const { agency } = useAgencyProvider();

  const handleRefresh = useCallback(async () => {
    if (agency) {
      return await api.subscriptions
        .getSubscriptionActive()
        .then((result) => {
          setResult(result);
          return result;
        })
        .catch((e) => {
          console.error(e);
          return null;
        });
    }
    return null;
  }, [agency, api]);

  const state = useMemo(
    () => ({
      subscription: result,
      refresh: handleRefresh,
      isFree: result?.subscription?.plan.id === "FREE",
    }),
    [result, handleRefresh]
  );

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  return (
    <SubscriptionContext.Provider value={state}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscriptionProvider = () => useContext(SubscriptionContext);
