import React from "react";

export function useCombinedRefs<T>(...refs: React.Ref<T>[]) {
  const targetRef = React.useRef<T>(null);

  React.useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === "function") {
        ref((targetRef as any).current);
      } else {
        (ref as any).current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
}
