import {
  AppBar,
  Box,
  IconButton,
  Paper,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import type { PDFDocumentProxy } from "pdfjs-dist";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import DownloadIcon from "@mui/icons-material/FileDownload";
import { customScrollbar } from "../../ServiestateTheme";
import "react-pdf/src/Page/AnnotationLayer.css";
import "react-pdf/src/Page/TextLayer.css";

export type PDFViewerProps = {
  fileUrl: string;
  fileName: string;
};

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const documentOptions = {
  standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
};

export const PDFViewer: FC<PDFViewerProps> = ({ fileUrl, fileName }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [buffer, setBuffer] = useState<Uint8Array | null>(null);
  const [scale, setScale] = useState(1);
  const [pdf, setPdf] = useState<PDFDocumentProxy | null>(null);

  const handleLoadSuccess = useCallback((pdfUntyped: any) => {
    const pdf: PDFDocumentProxy = pdfUntyped;
    setNumPages(pdf.numPages);
    setPdf(pdf);
    pdf.getData().then((c) => setBuffer(c as Uint8Array));
  }, []);

  const handleDownloadFile = useCallback(() => {
    if (!buffer) return;

    const url = window.URL.createObjectURL(new Blob([buffer]));

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  }, [buffer, fileName]);

  // const handlePrint = useCallback(() => {
  //   if (!pdf) return;
  //   pdf.d
  // }, [pdf]);

  useEffect(() => {
    if (!pdf) return;
    pdf.getPage(1);
  }, [pdf]);

  console.log(fileUrl);

  return (
    <Paper
      elevation={10}
      sx={{
        border: "1px solid #e0e0e0",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // maxWidth,
        // maxHeight,
        height: "100%",
        overflow: "hidden",
      }}
    >
      <AppBar position="sticky" color="default">
        <Toolbar variant="dense">
          <Typography flex={1}>{fileName}</Typography>
          <IconButton edge="start" onClick={() => setScale(scale - 0.1)}>
            <ZoomOutIcon />
          </IconButton>
          <IconButton edge="start" onClick={() => setScale(scale + 0.1)}>
            <ZoomInIcon />
          </IconButton>
          <IconButton edge="start" onClick={handleDownloadFile}>
            <DownloadIcon />
          </IconButton>
          {/* <IconButton edge="start" onClick={handlePrint}>
            <PrintIcon />
          </IconButton> */}
        </Toolbar>
      </AppBar>

      <DocumentContainer>
        <Document
          file={fileUrl}
          onLoadSuccess={handleLoadSuccess}
          onLoadError={(e) => {
            console.error("load error", e);
          }}
          onSourceError={(e) => console.error("source error", e)}
          options={documentOptions}
        >
          <DocumentSubContainer>
            {Array.from(new Array(numPages), (_, index) => (
              <PageContainer key={`page_${index + 1}`}>
                <Page pageNumber={index + 1} scale={scale} />
              </PageContainer>
            ))}
          </DocumentSubContainer>
        </Document>
      </DocumentContainer>
    </Paper>
  );
};

const DocumentContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: "auto",
  overflow: "auto",
  backgroundColor: "#e0e0e0",
  ...customScrollbar,
}));

const DocumentSubContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  padding: theme.spacing(1),
  gap: theme.spacing(1),
}));

const PageContainer = styled(Paper)(({ theme }) => ({
  margin: "0 auto",
}));
