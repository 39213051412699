import { Role } from "@serviestate/shared/types";
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuthProvider } from "./auth.provider";
import { useAgencyProvider } from "./agency.provider";

const RoleContext = React.createContext<{
  role: Role | null;
  loading: boolean;
}>({ role: null, loading: false });

export const RoleProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<Role | null>(null);
  const { agency } = useAgencyProvider();
  const [{ api }, setJwt] = useAuthProvider();
  const [loading, setLoading] = useState(true);

  const loadRole = useCallback(() => {
    try {
      if (agency) {
        setLoading(true);
        api.user
          .getCurrentRole()
          .then((role) => {
            setState(role);
          })
          .catch((e) => {
            console.error(e);
            api.clearCredentials();
            setJwt(null);
          });
      }
    } finally {
      setLoading(false);
    }
  }, [api, agency, setJwt]);

  useEffect(() => {
    loadRole();
  }, [loadRole]);

  return (
    <RoleContext.Provider value={{ role: state, loading }}>
      {children}
    </RoleContext.Provider>
  );
};

export const useRoleProvider = () => useContext(RoleContext);
