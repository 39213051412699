import { ServiestateApi } from "@serviestate/sdk";
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import config from "../config";
import fetchAdapter from "@serviestate/axios-fetch-adapter";

const initialJwt = localStorage.getItem("user.jwt");

const defaultContext: {
  jwt: string | null;
  jwt_data: Record<string, unknown> | null;
  api: ServiestateApi;
  isAuthenticated: boolean;
} = {
  jwt: initialJwt,
  jwt_data: initialJwt ? parseJwt(initialJwt) : null,
  api: new ServiestateApi({
    adapter: fetchAdapter,
    autoRefresh: true,
    endpoint: config.API_ENDPOINT,
  }),
  isAuthenticated: !!initialJwt,
};

initialJwt && defaultContext.api.setCredentials(initialJwt);

const AuthContext = React.createContext<
  [typeof defaultContext, (jwt: string | null) => void]
>([defaultContext, () => {}]);

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<typeof defaultContext>(defaultContext);

  const setJwt = useCallback(
    (jwt: string | null) => {
      if (jwt) {
        localStorage.setItem("user.jwt", jwt);
        state.api.setCredentials(jwt);
        setState((state) => ({
          ...state,
          jwt,
          jwt_data: parseJwt(jwt),
          isAuthenticated: true,
        }));
      } else {
        localStorage.removeItem("user.jwt");
        state.api.clearCredentials();
        setState((state) => ({
          ...state,
          jwt: null,
          jwt_data: null,
          isAuthenticated: false,
        }));
      }
    },
    [state.api]
  );

  useEffect(() => {
    state.api.on("auto-refresh", (jwt) => setJwt(jwt as string));
    state.api.on("unauthorized", () => setJwt(null));
  }, [state.api, setJwt]);

  return (
    <AuthContext.Provider value={[state, setJwt]}>
      {children}
    </AuthContext.Provider>
  );
};

function parseJwt(token: string) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export const useAuthProvider = () => useContext(AuthContext);
