import {
  Alert,
  Box,
  Chip,
  Hidden,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FC, useCallback, useState } from "react";
import { TableCellNoWrap } from "../../atoms/table-cell-nowrap";
import { TableRowClickable } from "../../atoms/table-row-clickable";
import { Contact } from "@serviestate/shared/types";
import { TableContactCell } from "../../molecules/table-contact-cell";
import Skeleton from "@mui/material/Skeleton";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { TableColumnSortable } from "../../atoms/table-column-sortable";

export type ContactsTableProps = {
  items: Contact[] | null;
  orderBy?: string | null;
  loading: boolean;
  onItemClick: (item: Contact) => void;
  onChangeOrder?: (order: string) => void;
};

export const ContactsTable: FC<ContactsTableProps> = ({
  loading,
  items: contacts,
  orderBy,
  onItemClick: onContactClick,
  onChangeOrder,
}) => {
  const [selectedContactId, setSelectedContactId] = useState<string | null>(
    null
  );

  const handleContactClick = useCallback(
    (contact: Contact) => () => {
      setSelectedContactId(contact.id);
      onContactClick(contact);
    },
    [onContactClick]
  );

  const handleSort = useCallback(
    (column: keyof Contact) => (sort: "asc" | "desc") => {
      onChangeOrder && onChangeOrder(`${sort === "asc" ? "" : "-"}${column}`);
    },
    [onChangeOrder]
  );

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableColumnSortable
              sortDirection={
                orderBy ? (orderBy[0] === "-" ? "desc" : "asc") : null
              }
              onSort={handleSort("display_name")}
            >
              Contacto
            </TableColumnSortable>
            <Hidden smDown>
              <TableCellNoWrap>Email</TableCellNoWrap>
              <TableCellNoWrap>Móvil</TableCellNoWrap>
              <TableCellNoWrap>Etiquetas</TableCellNoWrap>
            </Hidden>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading &&
            contacts &&
            contacts.map((contact) => (
              <TableRowClickable
                selected={
                  !!selectedContactId && contact.id === selectedContactId
                }
                key={`contact_${contact.id}`}
                onClick={handleContactClick(contact)}
              >
                <TableContactCell contact={contact} />
                <Hidden smDown>
                  <TableCellNoWrap>{contact.email ?? "-"}</TableCellNoWrap>
                  <TableCellNoWrap>{contact.mobile ?? "-"}</TableCellNoWrap>
                  <TableCell>
                    {contact.tags && contact.tags.length > 0 ? (
                      <Stack direction="row" spacing={1}>
                        {contact.tags.map((x) => (
                          <Chip size="small" label={x} />
                        ))}
                      </Stack>
                    ) : (
                      <span>-</span>
                    )}
                  </TableCell>
                </Hidden>
              </TableRowClickable>
            ))}
          {loading &&
            new Array(5)
              .fill(0)
              .map((_, index) => (
                <FakeContactRow key={`contact_skeleton_${index}`} />
              ))}
          {!loading && contacts?.length === 0 && (
            <TableRow>
              <TableCell colSpan={4} sx={{ padding: 0 }}>
                <Alert color="info" icon={<InfoIcon />}>
                  ¡Todavía no tienes ningún contacto! Añádelos y centraliza tu operativa en Serviestate.
                </Alert>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const FakeContactRow = () => {
  return (
    <TableRow>
      <TableCell>
        <Box display="flex" gap={2} alignItems="center" justifyContent="center">
          <Skeleton variant="circular" width={40} height={40} />
          <Box flex={1}>
            <Skeleton animation="wave" />
            <Hidden smUp>
              <Skeleton animation="wave" />
            </Hidden>
          </Box>
        </Box>
      </TableCell>
      <Hidden smDown>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" />
        </TableCell>
      </Hidden>
    </TableRow>
  );
};
