import config from "./config";

declare global {
  interface Window {
    grecaptcha: ReCaptchaInstance;
  }
}

interface ReCaptchaInstance {
  ready: (cb: () => any) => any;
  execute: (id: string, options: ReCaptchaExecuteOptions) => Promise<string>;
}

interface ReCaptchaExecuteOptions {
  action?: string;
}

export function loadRecaptcha() {
  document.getElementsByTagName("head");
  const script = document.createElement("script");
  script.src = `https://www.google.com/recaptcha/api.js?render=${config.RECAPTCHA_KEY}`;
  document.head.appendChild(script);

  return new Promise<void>((resolve) => {
    function waitRecaptcha() {
      if (!window.grecaptcha) {
        setTimeout(waitRecaptcha, 100);
        return;
      }
      window.grecaptcha.ready(() => resolve());
    }
    waitRecaptcha();
  });
}
