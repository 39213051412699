import {
  Alert,
  Badge,
  Box,
  Card,
  CardMedia,
  Chip,
  Grid,
  Skeleton,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FC, useCallback } from "react";
import { Property } from "@serviestate/shared/types";
import { FiTarget } from "react-icons/fi";
import { ImStatsBars, ImStack } from "react-icons/im";
import { formatNumber, getContentUrl, formatPeriodicity } from "../../utils";
import { WidgetStatInfo } from "../molecules/widget-stat-info";
import { IconPropertyType } from "../atoms/icon-property-type";
import { useAPI } from "../../hooks/use-api";
import { useFetchController } from "../../hooks/use-fetch-controller";
import { SlLocationPin } from "react-icons/sl";
import { RxDimensions } from "react-icons/rx";
import { MdOutlineDocumentScanner, MdPayment } from "react-icons/md";
import { PortalImage } from "../atoms/portal-image";
export type PropertyCardProps = {
  item: Property;
  onClick?: (property: Property) => void;
  footer?: React.ReactElement;
  variant?: "complete" | "compact";
};

export const PropertyCard: FC<PropertyCardProps> = ({
  item,
  footer,
  variant,
  onClick,
}) => {
  variant = variant ?? "complete";
  const theme = useTheme();

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.only("xs")
  );

  const mainPhotoUrl = item.main_photo
    ? getContentUrl(item.main_photo.document_id, {
        fit: "cover",
        width: 160,
        height: 160,
      })
    : "/images/no-photo.png";

  const channelsEnabled = item.channels.filter((x) => x.enabled);

  return (
    <Card
      sx={{
        display: "flex",
        cursor: onClick ? "pointer" : "unset",
        border: "1px solid #f3f3f3",
        backroundColor: "#fff",
      }}
      onClick={() => onClick && onClick(item)}
    >
      {!isMobile && (
        <CardMedia
          component="img"
          sx={{
            width: 200,
            height: 200,
            borderRight: "1px solid #f2f2f2",
          }}
          image={mainPhotoUrl}
        />
      )}
      <Box
        sx={{
          flex: 1,
          padding: 2,
          paddingBottom: 1,
          gap: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          [theme.breakpoints.up("md")]: {
            flexDirection: "row",
          },
        }}
      >
        <Box display="flex" flexDirection="column" flex={1} overflow="hidden">
          <Box display="flex" flexDirection="column" overflow="hidden" gap={1}>
            <Box display="flex" flex={1} overflow="hidden">
              <Box
                display="flex"
                gap={1.5}
                flex={{ xs: 1, md: variant === "complete" ? "unset" : 1 }}
                overflow="hidden"
              >
                <Box pl={0.4}>
                  <IconPropertyType propertyType={item.type} height="24px" />
                </Box>
                <Typography
                  variant="h5"
                  noWrap
                  textOverflow="ellipsis"
                  overflow="hidden"
                >
                  {item.subtype.name}{" "}
                  {item.geo_address && <>en {item.geo_address.locality}</>}
                </Typography>
              </Box>
              <Chip
                sx={{ marginLeft: 1 }}
                label={
                  item.archived_at
                    ? "Archivado"
                    : item.published_at
                    ? "Publicado"
                    : "Borrador"
                }
                color={
                  item.archived_at
                    ? "error"
                    : item.published_at
                    ? "success"
                    : "warning"
                }
                size="small"
              />
            </Box>
            {item.geo_address && (
              <Box
                display="flex"
                alignItems="center"
                gap={1}
                overflow="hidden"
                color="#444"
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <SlLocationPin size={20} color="#444" />
                </Box>
                <Typography
                  textOverflow={"ellipsis"}
                  whiteSpace={"nowrap"}
                  overflow="hidden"
                >
                  {item.geo_address.address}
                </Typography>
              </Box>
            )}
            <Grid container spacing={1}>
              <Grid item>
                <Typography
                  variant="subtitle1"
                  color="#444"
                  display="flex"
                  alignItems="center"
                  gap={1}
                  overflow="hidden"
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <MdOutlineDocumentScanner size={20} color="#444" />
                  </Box>
                  {item.reference}
                </Typography>
              </Grid>
              {item.main_transaction && (
                <Grid item>
                  <Box display="flex" alignItems="center" gap={1} marginX={2}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <MdPayment size={20} color="#444" />
                    </Box>
                    <Typography variant="body1">
                      {formatNumber(item.main_transaction.final_price)}€
                      <Typography variant="caption">
                        {formatPeriodicity(item.main_transaction.periodicity)}
                      </Typography>
                    </Typography>
                  </Box>
                </Grid>
              )}
              {!!item.attributes?.surface && (
                <Grid item>
                  <Typography
                    component="div"
                    variant="body1"
                    display="flex"
                    alignItems="center"
                    gap={1}
                    textOverflow={"ellipsis"}
                    whiteSpace={"nowrap"}
                    overflow="hidden"
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <RxDimensions size={20} color="#444" />
                    </Box>
                    <span>
                      {item.attributes?.surface} m<sup>2</sup>
                    </span>
                  </Typography>
                </Grid>
              )}
            </Grid>
            {footer && (
              <Grid container spacing={2}>
                <Grid item>{footer}</Grid>
              </Grid>
            )}
          </Box>
          {channelsEnabled.length > 0 && (
            <Box
              alignItems={"center"}
              gap={2}
              py={1}
              display="flex"
              flex={{ xs: 1, md: variant === "complete" ? "unset" : 1 }}
            >
              Publicado en
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                {item.channels
                  .filter((x) => x.enabled)
                  .map((x) => (
                    <Badge
                      key={x.channel_code}
                      color={x.status !== "syncronized" ? "error" : "success"}
                      variant="dot"
                    >
                      <PortalImage size={30} channel={x.channel} />
                    </Badge>
                  ))}
              </Box>
            </Box>
          )}
        </Box>
        {variant === "complete" && (
          <Box display="flex" gap={1.5}>
            <WidgetStatInfo
              color="success"
              icon={FiTarget}
              label="Cruces"
              count={item.stats.opportunities}
              size="small"
            />
            <WidgetStatInfo
              color="warning"
              icon={ImStatsBars}
              label="Visitas Web"
              count={item.stats.web_visits}
              size="small"
            />
            <WidgetStatInfo
              color="primary"
              icon={ImStack}
              label="Leads"
              count={item.stats.leads}
              size="small"
            />
          </Box>
        )}
      </Box>
    </Card>
  );
};

export const PropertyCardRemote: FC<
  Omit<PropertyCardProps, "item"> & { propertyId: string }
> = ({ propertyId, ...rest }) => {
  const api = useAPI();
  const fetch = useCallback(
    () => api.properties.detail(propertyId),
    [api, propertyId]
  );
  const { data, loading } = useFetchController(fetch, { autoload: true });

  if (loading) return <Skeleton />;
  if (!data) return <Alert>No hemos podido cargar la ficha del inmueble</Alert>;
  return <PropertyCard item={data} {...rest} />;
};
