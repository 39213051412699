import {
  Box,
  Card,
  CardMedia,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FC } from "react";
import { FiTarget } from "react-icons/fi";
import { ImStatsBars } from "react-icons/im";
import {
  MdCalendarToday,
  MdOutlineBusinessCenter,
  MdPersonSearch,
} from "react-icons/md";
import { formatCurrency } from "../../utils";
import { WidgetStatInfo } from "../molecules/widget-stat-info";
import { IconPropertyType } from "../atoms/icon-property-type";
import dayjs from "dayjs";
import { ExternalProperty } from "@serviestate/shared";
import { SlLocationPin } from "react-icons/sl";
import { RxDimensions } from "react-icons/rx";
import { PortalImage } from "../atoms/portal-image";

export type ExternalPropertyCardProps = {
  item: ExternalProperty;
  onClick?: (property: ExternalProperty) => void;
  footer?: React.ReactElement;
  variant?: "complete" | "compact";
};

export const ExternalPropertyCard: FC<ExternalPropertyCardProps> = ({
  item,
  footer,
  variant,
  onClick,
}) => {
  variant = variant ?? "complete";
  const theme = useTheme();

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.only("xs")
  );

  const mainPhotoUrl = item.main_photo
    ? item.main_photo.src
    : "/images/no-photo.png";

  return (
    <Card
      sx={{
        display: "flex",
        cursor: onClick ? "pointer" : "unset",
        border: "1px solid #f3f3f3",
        backroundColor: "#fff",
      }}
      onClick={() => onClick && onClick(item)}
    >
      {!isMobile && (
        <CardMedia
          component="img"
          sx={{ width: 160, height: 160, borderRight: "1px solid #f2f2f2" }}
          image={mainPhotoUrl}
        />
      )}
      <Box
        sx={{
          flex: 1,
          padding: 2,
          paddingBottom: 1,
          gap: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          [theme.breakpoints.up("md")]: {
            flexDirection: "row",
          },
        }}
      >
        <Box display="flex" flexDirection="column" flex={1} overflow="hidden">
          <Box display="flex" flexDirection="column" overflow="hidden">
            <Box display="flex" flex={1} overflow="hidden" marginBottom={2}>
              <Box
                display="flex"
                gap={1.5}
                flex={{ xs: 1, md: variant === "complete" ? "unset" : 1 }}
                overflow="hidden"
              >
                <Box pl={0.4}>
                  <IconPropertyType
                    propertyType={item.property_type}
                    height="24px"
                  />
                </Box>
                <Typography
                  variant="h5"
                  noWrap
                  textOverflow="ellipsis"
                  overflow="hidden"
                >
                  {item.property_subtype.name}{" "}
                  {item.geo_address && <>en {item.geo_address.locality}</>}
                </Typography>
              </Box>
              <Box px={2}>
                <PortalImage size={25} channel={item.channel} />
              </Box>
            </Box>
            {item.geo_address && (
              <Box
                display="flex"
                alignItems="center"
                gap={1}
                overflow="hidden"
                marginBottom={0.5}
                sx={{ display: "inline-flex", alignItems: "center" }}
              >
                <Box>
                  <SlLocationPin size={20} color="#444" />
                </Box>
                <Typography
                  color="text.secondary"
                  textOverflow={"ellipsis"}
                  whiteSpace={"nowrap"}
                  overflow="hidden"
                >
                  {item.geo_address.address}
                </Typography>
              </Box>
            )}
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              overflow="hidden"
              sx={{ display: "inline-flex", alignItems: "center" }}
            >
              <MdCalendarToday size={20} color="#444" />
              <Typography
                variant="subtitle1"
                color="text.secondary"
                display="flex"
                alignItems="center"
                gap={1}
                marginBottom={0.5}
              >
                <span>
                  Añadido el{" "}
                  <b>
                    {dayjs(item.created_at).format("D")} de{" "}
                    {dayjs(item.created_at).format("MMMM")} a las{" "}
                    {dayjs(item.created_at).format("HH:mm")}
                  </b>
                </span>
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              overflow="hidden"
              sx={{ display: "inline-flex", alignItems: "center" }}
            >
              <RxDimensions size={20} color="#444" />
              <Typography
                variant="subtitle1"
                color="text.secondary"
                display="flex"
                alignItems="center"
                gap={1}
              >
                {item.useful_surface ??
                  item.builded_surface ??
                  item.plot_surface}{" "}
                m²
              </Typography>
            </Box>
          </Box>
          {footer}
        </Box>
        {variant === "complete" && (
          <Box display="flex" gap={1.5}>
            <WidgetStatInfo
              color="primary"
              icon={FiTarget}
              label={formatCurrency(item.price)}
              size="small"
              count={-1}
            />
            <WidgetStatInfo
              color="warning"
              icon={ImStatsBars}
              label={item.transaction_type.name}
              size="small"
              count={-1}
            />
            <WidgetStatInfo
              color={
                item.external_contact.is_professional ? "error" : "success"
              }
              icon={
                item.external_contact.is_professional
                  ? MdOutlineBusinessCenter
                  : MdPersonSearch
              }
              label={
                item.external_contact.is_professional ? "Agencia" : "Particular"
              }
              size="small"
              count={-1}
            />
          </Box>
        )}
      </Box>
    </Card>
  );
};
