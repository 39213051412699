import { Box, styled } from "@mui/material";
import { FC, PropsWithChildren } from "react";

export type CardListProps = PropsWithChildren<{
  clickable?: boolean;
}>;

export const CardList: FC<CardListProps> = ({ children, clickable }) => {
  return <Container clickable={clickable}>{children}</Container>;
};

const Container = styled(Box, {
  shouldForwardProp: (prop) =>
    !["clickable", "asGrid", "gridColumns"].includes(prop.toString()),
})<{ clickable?: boolean }>(({ theme, clickable }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  ...(clickable && {
    ".MuiCard-root": {
      boxShadow: theme.shadows[3],
      transition: theme.transitions.create(["transform", "box-shadow"], {}),
    },
    ".MuiCard-root:hover": {
      cursor: "pointer",
      boxShadow: theme.shadows[5],
      transform: "scale(1.01)",
    },
  }),
}));
