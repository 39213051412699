import { Box, IconButton } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import { TableCellNoWrap } from "./table-cell-nowrap";
import {
  TiArrowUnsorted,
  TiArrowSortedUp,
  TiArrowSortedDown,
} from "react-icons/ti";

export const TableColumnSortable: FC<
  PropsWithChildren<{
    sortDirection?: "asc" | "desc" | null;
    onSort: (sort: "asc" | "desc") => void;
  }>
> = ({ sortDirection, onSort, children }) => {
  return (
    <TableCellNoWrap>
      <Box display="flex" alignItems="center" gap={1}>
        <span>{children}</span>
        {!sortDirection && (
          <IconButton size="small" onClick={() => onSort("asc")}>
            <TiArrowUnsorted color="#8c8c8c" />
          </IconButton>
        )}
        {sortDirection === "asc" && (
          <TiArrowSortedUp onClick={() => onSort("desc")} />
        )}
        {sortDirection === "desc" && (
          <TiArrowSortedDown onClick={() => onSort("asc")} />
        )}
      </Box>
    </TableCellNoWrap>
  );
};
