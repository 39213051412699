import { Box, Paper, styled } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import { LogoServiestate } from "../../atoms/logo-serviestate";

export const PaymentLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <BoxContainer>
      <LogoServiestate />
      <Content elevation={10}>{children}</Content>
    </BoxContainer>
  );
};

const BoxContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const Content = styled(Paper)(({ theme }) => ({
  maxWidth: "600px",
  width: "100%",
  padding: theme.spacing(3),
}));
