import {
  Box,
  CircularProgress,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { createElement } from "react";
import type { FC } from "react";
import type { IconType } from "react-icons";

export type WidgetStatInfoProps = {
  color: "primary" | "warning" | "success" | "error";
  label: string;
  count: number;
  icon?: IconType;
  fullHeight?: boolean;
  size?: "small" | "normal";
  loading?: boolean;
};

export const WidgetStatInfo: FC<WidgetStatInfoProps> = ({
  icon,
  label,
  count,
  color,
  fullHeight,
  size,
  loading,
}) => {
  size = size ?? "normal";
  const theme = useTheme();
  const palette = theme.palette[color];

  const isTabletOrUp = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up("md")
  );

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: 1,
        backgroundColor: palette.light,
        borderRadius: 1.5,
        minWidth: 70,
        height: fullHeight ? "100%" : "auto",
        [theme.breakpoints.up("md")]: {
          minWidth: 120,
          flexDirection: "column",
          gap: 0.2,
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          zIndex: 1,
          display: "flex",
          flexDirection: "row",
          gap: 1,
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          [theme.breakpoints.up("md")]: {
            flexDirection: "column",
          },
        }}
      >
        {icon &&
          createElement(icon, {
            color: palette.contrastText,
            size: isTabletOrUp ? 30 : 18,
          })}

        {
          <Typography
            color={palette.contrastText}
            fontWeight="bold"
            textAlign="center"
            sx={{
              fontSize: size === "small" ? "0.6rem" : "1rem",
              padding: 0,
              margin: 0,
              [theme.breakpoints.up("md")]: {
                fontSize: size === "small" ? "1rem" : "1.25rem",
              },
            }}
          >
            {label}
          </Typography>
        }
        {loading && <CircularProgress color="inherit" size={30} />}
        {!loading && count >= 0 && (
          <Typography
            color={palette.contrastText}
            fontWeight="bold"
            textAlign="center"
            sx={{
              fontSize: "1rem",
              padding: 0,
              margin: 0,
              [theme.breakpoints.up("md")]: {
                lineHeight: "1.75rem",
                fontSize: "1.75rem",
              },
            }}
          >
            {count}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
