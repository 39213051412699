import { AgencyWallet } from "@serviestate/shared/types";
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAuthProvider } from "./auth.provider";
import { useAgencyProvider } from "./agency.provider";

const WalletsContext = React.createContext<{
  wallets: AgencyWallet[];
  refresh: () => Promise<void>;
}>({ wallets: [], refresh: () => Promise.resolve() });

export const WalletsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [wallets, setWallets] = useState<AgencyWallet[]>([]);
  const [{ api }] = useAuthProvider();
  const { agency } = useAgencyProvider();

  const handleRefresh = useCallback(async () => {
    if (agency) {
      await api.wallets
        .getWallets()
        .then((wallets) => {
          setWallets(wallets);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [agency, api]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  const state = useMemo(
    () => ({ wallets, refresh: handleRefresh }),
    [wallets, handleRefresh]
  );

  return (
    <WalletsContext.Provider value={state}>{children}</WalletsContext.Provider>
  );
};

export const useWalletsProvider = () => useContext(WalletsContext);
