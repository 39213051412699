import {
  Alert,
  Box,
  Chip,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { Demand } from "@serviestate/shared/types";
import dayjs from "dayjs";
import { useAPI } from "../../../hooks/use-api";
import { useFetchController } from "../../../hooks/use-fetch-controller";
import availableExtras from "../../../property-extras";
import { TableCellNoWrap } from "../../atoms/table-cell-nowrap";
import { TableRowClickable } from "../../atoms/table-row-clickable";

export type DemandsTableProps = {
  items: Demand[] | null;
  onItemClick: (item: Demand) => void;
  loading: boolean;
};

export const DemandsTable: FC<DemandsTableProps> = ({
  loading,
  items,
  onItemClick,
}) => {
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const handleItemClick = useCallback(
    (item: Demand) => () => {
      setSelectedId(item.id);
      onItemClick(item);
    },
    [onItemClick]
  );

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCellNoWrap>Cruces</TableCellNoWrap>
            <TableCellNoWrap>Contacto</TableCellNoWrap>
            <TableCellNoWrap>Zonas</TableCellNoWrap>
            <TableCellNoWrap>Tipo</TableCellNoWrap>
            <TableCellNoWrap>Subtipos</TableCellNoWrap>
            <TableCellNoWrap>Transacción</TableCellNoWrap>
            <TableCellNoWrap>Precio mín.</TableCellNoWrap>
            <TableCellNoWrap>Precio máx.</TableCellNoWrap>
            <TableCellNoWrap>Habitaciones mín.</TableCellNoWrap>
            <TableCellNoWrap>Baños mín.</TableCellNoWrap>
            <TableCellNoWrap>Superficie mín.</TableCellNoWrap>
            <TableCellNoWrap>Extras</TableCellNoWrap>
            <TableCellNoWrap>Conservación</TableCellNoWrap>
            <TableCellNoWrap>Fecha alta</TableCellNoWrap>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading &&
            items &&
            items.map((item) => (
              <DemandRow
                selected={!!selectedId && item.id === selectedId}
                key={`item_${item.id}`}
                item={item}
                onClick={handleItemClick(item)}
              />
            ))}
          {loading &&
            new Array(10)
              .fill(0)
              .map((_, index) => (
                <FakeItemRow key={`demands_item_skeleton_${index}`} />
              ))}
          {!loading && items?.length === 0 && (
            <TableRow>
              <TableCell colSpan={14} sx={{ padding: 0 }}>
                <Alert color="info" icon={<InfoIcon />}>
                  No tienes demandas activas. ¡Crea una demanda ahora y se detectarán automáticamente los cruces con tus inmuebles publicados!
                </Alert>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const DemandRow: FC<{
  item: Demand;
  selected: boolean;
  onClick: (item: Demand) => void;
}> = ({ selected, onClick, item }) => {
  const api = useAPI();

  const fetchContact = useCallback(
    () => api.contacts.detail(item.contact_id),
    [api, item]
  );

  const fetchPropertyType = useCallback(
    async () => await api.propertyTypes.detail(item.property_type_id),
    [api, item]
  );

  const fetchPropertySubtypes = useCallback(
    async () =>
      await Promise.all(
        (item.property_subtypes_id ?? []).map((x) =>
          api.propertySubTypes.detail(x)
        )
      ),
    [api, item]
  );

  const fetchTransactionType = useCallback(
    async () =>
      await api.propertyTransactionTypes.detail(
        item.property_transaction_type_id
      ),
    [api, item]
  );

  const fetchConservation = useCallback(
    async () =>
      item.conservation
        ? await api.attributes.getItem("conservation", item.conservation)
        : null,
    [api, item]
  );

  const extras = useMemo(
    () =>
      item.extras.map(
        (x) => availableExtras.find((a) => a.code === x) ?? { code: x, name: x }
      ),
    [item]
  );

  const { data: contact, loading: loadingContact } = useFetchController(
    fetchContact,
    { autoload: true }
  );

  const { data: propertyType, loading: loadingPropertyType } =
    useFetchController(fetchPropertyType, {
      autoload: true,
    });

  const { data: propertySubtypes, loading: loadingPropertySubtypes } =
    useFetchController(fetchPropertySubtypes, {
      autoload: true,
    });

  const { data: transactionType, loading: loadingTransactionType } =
    useFetchController(fetchTransactionType, {
      autoload: true,
    });

  const { data: conservation, loading: loadingConservation } =
    useFetchController(fetchConservation, {
      autoload: true,
    });

  const handleClick = useCallback(() => {
    onClick(item);
  }, [onClick, item]);

  return (
    <TableRowClickable selected={selected} onClick={handleClick}>
      <TableCellNoWrap>{item.matches_count}</TableCellNoWrap>
      <TableCellNoWrap>
        {loadingContact ? <CircularProgress /> : contact?.display_name}
      </TableCellNoWrap>
      <TableCell>
        <Box display="flex" gap={0.5}>
          {item.zones.map((x) => (
            <Chip
              key={`zone_${x.geo_zone_id}`}
              size="small"
              label={x.geo_zone.computed_name}
            />
          ))}
        </Box>
      </TableCell>
      <TableCellNoWrap>
        {loadingPropertyType ? <CircularProgress /> : propertyType?.name}
      </TableCellNoWrap>
      <TableCell>
        <Box display="flex" gap={0.5}>
          {loadingPropertySubtypes ? (
            <CircularProgress />
          ) : propertySubtypes?.length === 0 ? (
            "Todos"
          ) : (
            propertySubtypes?.map((x) => (
              <Chip key={`subtype_${x.id}`} size="small" label={x.name} />
            ))
          )}
        </Box>
      </TableCell>
      <TableCellNoWrap>
        {loadingTransactionType ? <CircularProgress /> : transactionType?.name}
      </TableCellNoWrap>
      <TableCellNoWrap>{item.price_min ?? "Indiferente"}</TableCellNoWrap>
      <TableCellNoWrap>{item.price_max ?? "Indiferente"}</TableCellNoWrap>
      <TableCellNoWrap>{item.rooms_min ?? "Indiferente"}</TableCellNoWrap>
      <TableCellNoWrap>{item.bathrooms_min ?? "Indiferente"}</TableCellNoWrap>
      <TableCellNoWrap>{item.surface_min ?? "Indiferente"}</TableCellNoWrap>
      <TableCell>
        <Grid container display="flex" gap={0.5}>
          {extras?.map((x) => (
            <Grid item key={`extra_${x.code}`}>
              <Chip label={x.name} size="small" />
            </Grid>
          )) ?? "Indiferente"}
        </Grid>
      </TableCell>
      <TableCellNoWrap>
        {loadingConservation ? (
          <CircularProgress />
        ) : (
          conservation?.label ?? item.conservation ?? "Indiferente"
        )}
      </TableCellNoWrap>
      <TableCellNoWrap>{dayjs(item.created_at).fromNow()}</TableCellNoWrap>
    </TableRowClickable>
  );
};

const FakeItemRow = () => {
  return (
    <TableRow>
      {new Array(14).fill(0).map((_, index) => (
        <TableCell key={`item_skeleton_${index}`}>
          <Skeleton />
        </TableCell>
      ))}
    </TableRow>
  );
};
