import { styled, TableRow } from "@mui/material";

export const TableRowClickable = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  transition: theme.transitions.create(["background-color"], {
    duration: theme.transitions.duration.shorter,
  }),
  "&:hover": {
    backgroundColor: theme.palette.grey[200],
  },
}));
