import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { Contact, Demand, Property } from "@serviestate/shared/types";
import { useNavigate } from "react-router-dom";
import { useAPI } from "../../../hooks/use-api";
import { useFetchController } from "../../../hooks/use-fetch-controller";
import { CardList } from "../../atoms/card-list";
import { InputSearch } from "../../atoms/input-search";
import { ContactsTable } from "../tables/contacts-table";
import { DemandsTable } from "../tables/demands-table";
import { PropertyCard } from "../property-card";
import CloseIcon from "@mui/icons-material/CloseOutlined";

export type SmartSeacherDialogProps = {
  open: boolean;
  onClose: () => void;
};

export const SmartSearcherDialog: FC<SmartSeacherDialogProps> = ({
  open,
  onClose,
}) => {
  const api = useAPI();
  const [input, setInput] = useState("");
  const inputSearchRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const fetchResults = useCallback(async () => {
    if (input.length === 0) return [];
    return await api.smart.search(input);
  }, [input, api]);

  const handleChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInput(e.target.value);
    },
    []
  );

  const { data: results, loading } = useFetchController(fetchResults, {
    autoload: true,
  });

  const demands = useMemo(
    () => results?.filter((x) => x.type === "demand") ?? [],
    [results]
  );

  const contacts = useMemo(
    () => results?.filter((x) => x.type === "contact") ?? [],
    [results]
  );

  const properties = useMemo(
    () => results?.filter((x) => x.type === "property") ?? [],
    [results]
  );

  const handleNavigateToProperty = useCallback(
    (property: Property) => {
      navigate(`/properties/${property.id}`);
      onClose();
    },
    [navigate, onClose]
  );
  const handleNavigateToContact = useCallback(
    (contact: Contact) => {
      navigate(`/contacts/${contact.id}`);
      onClose();
    },
    [navigate, onClose]
  );
  const handleNavigateToDemand = useCallback(
    (demand: Demand) => {
      navigate(`/demands/${demand.id}`);
      onClose();
    },
    [navigate, onClose]
  );

  useEffect(() => {
    if (open) {
      let timeout: NodeJS.Timeout | null = null;
      let waiterCallback = () => {
        if (!inputSearchRef.current) {
          timeout = setTimeout(waiterCallback, 100);
        } else {
          timeout = setTimeout(() => {
            inputSearchRef.current?.focus();
          }, 100);
        }
      };

      waiterCallback();

      return () => {
        if (timeout) clearTimeout(timeout);
      };
    } else {
      setInput("");
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      inputMode="search"
      fullScreen
      autoFocus
    >
      <DialogTitleStyled>
        <Typography variant="h4" flex={1}>
          Búsqueda inteligente
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitleStyled>
      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        <Box>
          <InputSearchContainer>
            <InputSearch
              id="full-searcher"
              label="Buscar"
              ref={inputSearchRef}
              onChange={handleChangeInput}
              fullWidth
            />
          </InputSearchContainer>
        </Box>

        <Box
          flex={1}
          sx={{
            ...(input.length === 0
              ? {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }
              : {}),
          }}
        >
          {input.length === 0 && (
            <BigSearchContainer>
              <BigSearchIcon />
              <DialogContentText>
                Escribe lo que quieras buscar y lo encontramos enseguida 😀
              </DialogContentText>
            </BigSearchContainer>
          )}
          {loading && <CircularProgress />}
          {input.length > 0 && !loading && results?.length === 0 && (
            <Alert color="info" icon={<InfoIcon />}>
              No se han encontrado resultados para la búsqueda
            </Alert>
          )}
          {!loading && demands.length > 0 && (
            <Box mb={3}>
              <Typography variant="h5">Demandas</Typography>
              <DemandsTable
                items={demands.map((x) => x.result as Demand)}
                loading={false}
                onItemClick={handleNavigateToDemand}
              />
            </Box>
          )}
          {!loading && contacts.length > 0 && (
            <Box mb={3}>
              <Typography variant="h5">Contactos</Typography>
              <ContactsTable
                items={contacts.map((x) => x.result as Contact)}
                loading={false}
                onItemClick={handleNavigateToContact}
              />
            </Box>
          )}
          {!loading && properties.length > 0 && (
            <Box mb={3}>
              <Typography variant="h5">Inmuebles</Typography>
              <CardList clickable>
                {properties.map((x) => (
                  <PropertyCard
                    key={x.result.id}
                    item={x.result as Property}
                    onClick={handleNavigateToProperty}
                    footer={
                      (x.result as Property).project ? (
                        <Box display="flex" gap={1} alignItems="center">
                          <Chip
                            size="small"
                            label={`Proyecto: ${
                              (x.result as Property).project?.project_name
                            }`}
                          />
                        </Box>
                      ) : (
                        <></>
                      )
                    }
                  />
                ))}
              </CardList>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};

const DialogTitleStyled = styled(DialogTitle)(({ theme }) => ({
  padding: theme.spacing(2, 3, 1, 3),
  display: "flex",
}));

const InputSearchContainer = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(1, 0),
}));

const BigSearchContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const BigSearchIcon = styled(SearchIcon)(({ theme }) => ({
  width: 80,
  height: 80,
}));
