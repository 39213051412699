import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import { FC, useCallback, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useUserProvider } from "../../providers/user.provider";
import { useAuthProvider } from "../../providers/auth.provider";
import { useNavigate } from "react-router-dom";
import { AgentAvatar } from "../atoms/agent-avatar";
import { useDialogController, useFetchController } from "@serviestate/react-ui";
import { useAPI } from "../../hooks/use-api";
import { MdCheck, MdClose } from "react-icons/md";
import { CiPlay1 } from "react-icons/ci";
import { AgencyUser, UserProfile } from "@serviestate/shared";
import { IoChevronDown } from "react-icons/io5";
import { HEADER_HEIGHT } from "./header";
import { useRoleProvider } from "../../providers/role.provider";

export const HeaderUser: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [{ jwt_data }, setJwt] = useAuthProvider();
  const { user } = useUserProvider();
  const { role } = useRoleProvider();
  const navigate = useNavigate();
  const isAdmin = jwt_data?.is_admin as boolean;

  const {
    open: handleOpenImpersonateDialog,
    close: handleCloseImpersonateDialog,
    visible: showImpersonateDialog,
  } = useDialogController();

  const {
    open: handleOpenChangeAgencyDialog,
    close: handleCloseChangeAgencyDialog,
    visible: showChangeAgencyDialog,
  } = useDialogController();

  const handleOpenMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleCloseMenu = useCallback(() => setAnchorEl(null), []);

  const handleLogout = useCallback(() => {
    setJwt(null);
    handleCloseMenu();
  }, [setJwt, handleCloseMenu]);

  const handleNavigateToMyAccount = useCallback(() => {
    navigate("/my-account");
    handleCloseMenu();
  }, [navigate, handleCloseMenu]);

  if (!user) return null;

  return (
    <>
      <Container onClick={handleOpenMenu}>
        <AgentAvatar userId={user.id} />
        <Box flex={1} sx={{ overflow: "hidden" }}>
          <Typography
            variant="body1"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {user.fullname}
          </Typography>
          {role && <Typography variant="body2">{role.name}</Typography>}
        </Box>
        <IconButton size="small">
          <IoChevronDown />
        </IconButton>
      </Container>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        MenuListProps={{
          "aria-labelledby": "user-menu",
        }}
      >
        <MenuItem onClick={handleNavigateToMyAccount}>Mi cuenta</MenuItem>
        <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
        <Divider key="divider" />
        <MenuItem
          onClick={() => {
            handleCloseMenu();
            handleOpenChangeAgencyDialog();
          }}
        >
          Cambiar de agencia
        </MenuItem>
        {isAdmin && [
          <Divider key="divider" />,
          <MenuItem
            key="impersonate"
            onClick={() => {
              handleCloseMenu();
              handleOpenImpersonateDialog();
            }}
          >
            Suplantar
          </MenuItem>,
        ]}
      </Menu>
      {isAdmin && (
        <AdminImpersonateDialog
          open={showImpersonateDialog}
          onClose={handleCloseImpersonateDialog}
        />
      )}
      <ChangeAgencyDialog
        open={showChangeAgencyDialog}
        onClose={handleCloseChangeAgencyDialog}
      />
    </>
  );
};

const ChangeAgencyDialog: FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  const api = useAPI();
  const [, setJwt] = useAuthProvider();
  const { user } = useUserProvider();

  const handleSelect = useCallback(
    async (userAgency: AgencyUser) => {
      const result = await api.auth.setAgency(userAgency.agency_id);
      setJwt(result.access_token);
      onClose();

      setTimeout(() => {
        window.location.reload();
      }, 120);
    },
    [api, setJwt, onClose]
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Agencias disponibles</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Agencia</TableCell>
                <TableCell>Perfil</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {user?.agencies.map((userAgency) => (
                <TableRow key={userAgency.agency_id}>
                  <TableCell>{userAgency.agency.name}</TableCell>
                  <TableCell>{userAgency.role.name}</TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => handleSelect(userAgency)}
                    >
                      <CiPlay1 />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};

const AdminImpersonateDialog: FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  const api = useAPI();
  const [, setJwt] = useAuthProvider();
  const fetchUsers = useCallback(() => api.user.findAllActive(), [api]);

  const { data } = useFetchController(fetchUsers, { autoload: true });

  const handleImpersonate = useCallback(
    async (user: UserProfile) => {
      const result = await api.user.impersonate(user.id);
      setJwt(result.access_token);
      onClose();
    },
    [api, setJwt, onClose]
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Suplantación de usuario</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Activo</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.fullname}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    {!user.disabled_at ? <MdCheck /> : <MdClose />}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => handleImpersonate(user)}
                    >
                      <CiPlay1 />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  backgroundColor: "transparent",
  padding: theme.spacing(0, 1),
  border: "none",
  cursor: "pointer",
  gap: theme.spacing(1.5),
  borderBottom: `1px solid ${theme.palette.divider}`,
  height: HEADER_HEIGHT,
  textAlign: "left",
}));
