import { Alert, LinearProgress } from "@mui/material";
import { useFetchController } from "@serviestate/react-ui";
import { FC, useCallback } from "react";
import { useAPI } from "../../../hooks/use-api";
import {
  ExternalPropertyCard,
  ExternalPropertyCardProps,
} from "../external-property-card";

export const ExternalPropertyCardRemote: FC<
  Omit<ExternalPropertyCardProps, "item"> & { externalPropertyId: string }
> = ({ externalPropertyId, ...rest }) => {
  const api = useAPI();

  const fetchProperty = useCallback(
    () => api.externalProperties.detail(externalPropertyId),
    [api, externalPropertyId]
  );

  const { data: externalProperty, loading } = useFetchController(
    fetchProperty,
    {
      autoload: true,
      displayErrors: false,
    }
  );

  if (loading) {
    return <LinearProgress />;
  }

  if (!externalProperty) {
    return (
      <Alert color="error" icon={null}>
        Error loading external property
      </Alert>
    );
  }

  return <ExternalPropertyCard item={externalProperty} {...rest} />;
};
