import { useCallback } from "react";
import { useAPI } from "../../hooks/use-api";
import { UserAvatar } from "./user-avatar";
import { useFetchController } from "@serviestate/react-ui";
import { getContentUrl } from "../../utils";

export function AgentAvatar({
  userId,
  size,
}: {
  userId: string;
  size?: number;
}) {
  const api = useAPI();

  const fetchAgent = useCallback(
    () => api.agents.detail(userId),
    [api, userId]
  );

  const fetchStatus = useCallback(
    () => api.user.getStatus(userId),
    [api, userId]
  );

  const { data: agent } = useFetchController(fetchAgent, { autoload: true });

  const { data: status } = useFetchController(fetchStatus, { autoload: true });

  return (
    <UserAvatar
      username={agent?.user.fullname ?? "loading"}
      avatar={
        agent?.user.avatar_id
          ? getContentUrl(agent.user.avatar_id, { width: 80, height: 80 })
          : null
      }
      size={size}
      status={status}
    />
  );
}
