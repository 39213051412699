export class SerenaSendMessageDto {
    chat_id;
    channel;
    content;
}
export class SerenaChatNotFoundError extends Error {
    constructor() {
        super("Chat not found");
    }
}
