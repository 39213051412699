import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { FC, PropsWithChildren } from "react";

export type ConfirmationDialogProps = PropsWithChildren<{
  open: boolean;
  title: string;
  labelNo?: string;
  labelYes?: string;
  isDangerConfirmation?: boolean;
  hiddenLabelNo?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}>;

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  open,
  title,
  children,
  labelNo,
  labelYes,
  isDangerConfirmation,
  hiddenLabelNo,
  onCancel,
  onConfirm,
}) => {
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        {(hiddenLabelNo === undefined || hiddenLabelNo === false) && (
          <Button
            autoFocus
            onClick={onCancel}
            variant="outlined"
            color={"secondary"}
          >
            {labelNo ?? "No"}
          </Button>
        )}

        <Button
          variant="outlined"
          onClick={onConfirm}
          color={isDangerConfirmation ? "error" : "primary"}
        >
          {labelYes ?? "Sí"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
