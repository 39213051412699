import { ListItem, styled, Typography, useTheme } from "@mui/material";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { IconBaseProps, IconType } from "react-icons";

export type SidebarMenuItemProps = {
  imagePath?: string;
  icon?: IconType;
  name: string;
  active?: boolean;
  path: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

export const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  imagePath,
  icon,
  name,
  active,
  path,
  onClick,
}) => {
  const theme = useTheme();

  return (
    <ListItem disablePadding>
      <ListItemButton
        onClick={onClick}
        to={path}
        className={clsx(active && "active")}
      >
        {imagePath ? (
          <Image src={imagePath} alt={name} />
        ) : (
          icon &&
          React.createElement<IconBaseProps>(icon, {
            size: "1.5rem",
            style: {
              transition: theme.transitions.create(["color"]),
            },
            color: theme.palette.text.secondary,
          })
        )}
        <Typography
          fontWeight={700}
          fontSize={16}
          sx={{ transition: (theme) => theme.transitions.easing.easeIn }}
        >
          {name}
        </Typography>
      </ListItemButton>
    </ListItem>
  );
};

const ListItemButton = styled(Link)(({ theme }) => ({
  display: "flex",
  width: "100%",
  flexDirection: "row",
  alignItems: "center",
  margin: theme.spacing(0.25, 1.5),
  padding: theme.spacing(1.5),
  textDecoration: "none",
  opacity: 0.7,
  gap: theme.spacing(1),
  transition: theme.transitions.create(["background-color", "opacity"], {
    duration: theme.transitions.duration.shorter,
  }),
  "&:hover,&.active": {
    backgroundColor: theme.palette.background.default,
    opacity: 1,
  },
}));

const Image = styled("img")((theme) => ({
  width: "32px",
  height: "32px",
}));
