import { UserProfile } from "@serviestate/shared/types";
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuthProvider } from "./auth.provider";

const UserContext = React.createContext<{
  user: UserProfile | null;
  loading: boolean;
  update: () => void;
}>({ user: null, loading: false, update: () => {} });

export const UserProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<UserProfile | null>(null);
  const [{ api, jwt }, setJwt] = useAuthProvider();
  const [loading, setLoading] = useState(true);

  const loadUser = useCallback(() => {
    try {
      if (jwt) {
        setLoading(true);
        api.auth
          .self()
          .then((user) => {
            setState(user);
          })
          .catch((e) => {
            console.error(e);
            api.clearCredentials();
            setJwt(null);
          });
      }
    } finally {
      setLoading(false);
    }
  }, [api, jwt, setJwt]);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  return (
    <UserContext.Provider value={{ user: state, loading, update: loadUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserProvider = () => useContext(UserContext);
