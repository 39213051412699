import { IconButton, Theme, useMediaQuery } from "@mui/material";
import { FC } from "react";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import { InputSearch } from "../atoms/input-search";
import { SmartSearcherDialog } from "../organisms/dialogs/smart-searcher-dialog";
import { useDialogController } from "@serviestate/react-ui";

export const HeaderSearchbar: FC = () => {
  const {
    visible: visibleSearchDialog,
    open: handleOpenSearchDialog,
    close: handleCloseSearchDialog,
  } = useDialogController();

  const showLargeInput = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up("sm")
  );

  return (
    <>
      {showLargeInput ? (
        <InputSearch
          id="fake-searcher"
          label="Buscar"
          onClick={handleOpenSearchDialog}
          readOnly
        />
      ) : (
        <IconButton aria-label="Example" onClick={handleOpenSearchDialog}>
          <SearchIcon />
        </IconButton>
      )}
      <SmartSearcherDialog
        open={visibleSearchDialog}
        onClose={handleCloseSearchDialog}
      />
    </>
  );
};
